import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Content from './view'
import AppSettings from '../../AppSettings'
import './index.css'

const abortController = () => typeof AbortController !== "undefined" ? new AbortController() : null

class ClientSelector extends Component {

    constructor() {

        super()

        this.state = {
            clients: [],
            selected: '',
            isLoaded: false,
            controller: abortController()
        }

        this.onSelectHandler = this.onSelectHandler.bind(this)
        this.onInitializeHandler = this.onInitializeHandler.bind(this)
    }

    onInitializeHandler() {

        const url = process.env.REACT_APP_CLIENTS_API_URL

        if (this.state.isLoaded) {
            return
        }
        if(this.state.controller !== null) {
            fetch(url, { signal: this.state.controller.signal })
            .then(response => response.json())
            .then(clients => {
                // TODO: La API de clientes debe devolver solo los clientes del usuario concreto
                const _clients = JSON.parse(sessionStorage.getItem('user.clients'))
                clients = clients.filter(x => _clients.indexOf(x.clientId) > -1)
                let selected = sessionStorage.getItem('client')
                if (selected === undefined || selected === null || selected === '-') {
                    selected = (clients.length > 0) ? clients[0].clientId : '-'
                }
                this.setState({
                    clients: clients,
                    selected: selected,
                    isLoaded: true
                })
                // TODO: modificar para obtener los dispositivos de un servicio web
                const settings = new AppSettings(),
                    devices = settings.getIds(selected)
                sessionStorage.setItem('clients', JSON.stringify(clients))
                sessionStorage.setItem('devices', JSON.stringify(devices))
                sessionStorage.setItem('client', selected)
                localStorage.setItem('client', selected)
                
            })
        } else {
            fetch(url)
            .then(response => response.json())
            .then(clients => {
                // TODO: La API de clientes debe devolver solo los clientes del usuario concreto
                const _clients = JSON.parse(sessionStorage.getItem('user.clients'))
                clients = clients.filter(x => _clients.indexOf(x.clientId) > -1)
                let selected = sessionStorage.getItem('client')
                if (selected === undefined || selected === null || selected === '-') {
                    selected = (clients.length > 0) ? clients[0].clientId : '-'
                }
                this.setState({
                    clients: clients,
                    selected: selected,
                    isLoaded: true
                })
                // TODO: modificar para obtener los dispositivos de un servicio web
                const settings = new AppSettings(),
                    devices = settings.getIds(selected)
                sessionStorage.setItem('clients', JSON.stringify(clients))
                sessionStorage.setItem('devices', JSON.stringify(devices))
                sessionStorage.setItem('client', selected)
                localStorage.setItem('client', selected)

            })
        }
            
            
    }

    componentDidMount() {
        this.onInitializeHandler()
    }

    componentWillUnmount() {
        if (this.state.controller !== null && this.state.controller !== undefined) {
            //this.state.controller.abort()
        }
    }

    onSelectHandler(selected) {
        this.setState({
            selected: selected
        })
        const settings = new AppSettings(),
            devices = settings.getIds(selected)
        sessionStorage.setItem('devices', JSON.stringify(devices))
        sessionStorage.setItem('client', selected)
        localStorage.setItem('client', selected)

        this.props.dispatch({
            type: 'CLIENT_SELECTED',
            clientId: selected
        })
        this.props.dispatch(push(`/gateway`))
        const profileMenu = document.querySelector('#profile-menu');
        const profileMenuButton = document.querySelector('.profile-menu-button');
        profileMenu.classList.add('profile-menu-close');
        profileMenuButton.classList.remove('profile-menu-button-active');
    }

    render() {
        return (
            <Content
                clients={this.state.clients}
                selected={this.state.selected}
                onSelectHandler={this.onSelectHandler}
            />
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(ClientSelector)