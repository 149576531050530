import { DEVICES_ACTIONS } from './constants'

/**
 * Obtiene la acción de carga de la página
 * @returns {Object} Referencia a los parámetros de acción
 */
export const setLoading = () => {
    return {
        type: DEVICES_ACTIONS.SET_LOADING,
        devices: [],
        isLoading: true
    }
}

/**
 * Obtiene la colección de dispositivos a representar
 * @returns {Function} Referencia a la función de carga
 */
export const getDevices = () => {
    return (dispatch) => {        
        const _devices = JSON.parse(sessionStorage.getItem('devices')),
            devices = _devices.filter(x => ((x.logicalId.indexOf('TMSIC-RPI') > -1) || (x.logicalId.indexOf('PMD') > -1)) || x.logicalId.indexOf('CONTENEDOR SIN DISPOSITIVO') > -1)

        dispatch({
            type: DEVICES_ACTIONS.SET_LOADED,
            devices: devices,
            isLoading: false
        })
    }
}