import React from 'react'
import PropTypes from 'prop-types'
import GoogleMap from '../GoogleMap'
import RoutePoint from '../RoutePoint'
import './index.css'

const DEFAULT_CENTER = [40.4167, -3.70325],
  DEFAULT_ZOOM = 14

const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length

const RouteMap = (props) => {

  const {
    path,
    zoom,
    color
  } = props

  let { center } = props

  if (path === undefined || path === null || path.length === 0) {
    return (
      <div className="route-map-container">
        <GoogleMap
          zoom={zoom}
          center={center}
          yesIWantToUseGoogleMapApiInternals
        />
      </div>
    )
  }

  center = [
    arrAvg(path.map(x => x.lat)),
    arrAvg(path.map(x => x.lng))
  ]

  const handleGoogleMapApi = (google) => {
    const _path = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: color,
      strokeOpacity: 1,
      strokeWeight: 2
    })
    _path.setMap(google.map)
  }

  return (
    <div className="route-map-container">
      <GoogleMap
        zoom={zoom}
        center={center}
        onGoogleApiLoaded={handleGoogleMapApi}
        yesIWantToUseGoogleMapApiInternals
      >
        {
          path.map((x, i) => {
            const last = path.length - 1
            let type = ''

            switch(i){
              case 0:
                type = 'START'
                break
              case last:
                type = 'END'
                break              
              default:
                type = 'ANY'
                break
            }            

            return (
              <RoutePoint 
                key={i} 
                point={x} 
                lat={x.lat} 
                lng={x.lng} 
                type={type}
              />
            )
          })
        }
      </GoogleMap>
    </div>
  )
}

RouteMap.propTypes = {
  path: PropTypes.array.isRequired,
  center: PropTypes.array,
  zoom: PropTypes.number,
  color: PropTypes.string
}

RouteMap.defaultProps = {
  path: [],
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  color: '#33BD4E'
}

export default RouteMap