import React from 'react'
import PropTypes from 'prop-types'

const Content = (props) => {

    const {
        clients,
        selected,
        onSelectHandler
    } = props

    return (
        <div className="client-selector">
            {
                (clients.length > 1)
                    ? <div className="form-group client-selector-form-group">
                        <select
                            className="form-control form-control-sm client-selector-custom"
                            onChange={(event) => onSelectHandler(event.target.value)}
                            value={selected}>
                            {
                                clients.map(x => <option 
                                                    key={x.clientId} 
                                                    value={x.clientId}
                                                    className="client-selector-custom-option"
                                                    >{x.clientName}</option>)
                            }
                        </select>
                    </div>
                    : ''
            }
        </div>
    )
}

Content.propTypes = {
    clients: PropTypes.array.isRequired,
    selected: PropTypes.string,
    onSelectHandler: PropTypes.func.isRequired
}

Content.defaultValues = {
    clients: [],
    selected: ''
}

export default Content