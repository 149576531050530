import React from 'react'
import PropTypes from 'prop-types'
import { FaTruck } from 'react-icons/fa'
import './index.css'

const Marker = (props) => {
  
  let { onClick } = props 

  const { 
    mark    
  } = props,
    date = new Date(mark.date)

    if(onClick === undefined || onClick === null){
      onClick = () => {}
    }

  return (
    <div className="map-marker">
      <FaTruck 
        onClick={() => onClick(mark)}
      />      
      <div className="map-marker-info">
        Matrícula: {mark.reference} <br/>
        Teléfono: {mark.phone} <br/>
        Empresa: {mark.company} <br/>
        Conductor: {mark.driver} <br/>
        Expedición: {mark.route} <br/>
        Hora: {date.toLocaleTimeString()} <br/>
        Fecha: {date.toLocaleDateString()}
      </div>
    </div>
  )
}

Marker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  mark: PropTypes.object.isRequired,
  onClick:PropTypes.func
}

export default Marker