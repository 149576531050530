import React from 'react'
import PropTypes from 'prop-types'
import {
  FaMapMarkerAlt,
  FaFlagCheckered
} from 'react-icons/fa'
import './index.css'

const RoutePoint = (props) => {

  const {
    point,
    type
  } = props,
    date = new Date(point.date)

  return (
    <div className="route-point">

      {
        type === 'START'
          ? <div className="route-point-marker">
            <FaMapMarkerAlt />
          </div>
          : ''
      }

      {
        type === 'END'
          ? <div className="route-point-marker">
            <FaFlagCheckered />
          </div>
          : ''
      }

      <div className="route-point-info">
        Hora: {date.toLocaleTimeString()}
      </div>
    </div>
  )
}

RoutePoint.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  point: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

export default RoutePoint