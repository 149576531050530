import { createUserManager } from 'redux-oidc';

const settings = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
    authority: process.env.REACT_APP_AUTHORITY,
    loadUserInfo: true,
    response_type: 'id_token',
    scope: 'openid profile'
}

const userManager = createUserManager(settings)

export default userManager