import React, {Component} from 'react';
import { Content, Footer, Header, Sidebar } from '..';
import Spinner from '../../Controls/Spinner';
import Toast from '../../Controls/Toast';
import AppRouter from '../../AppRouter';
import ErrorBoundary from '../../ErrorBoundary'
import AppBreadcrumb from '../../Controls/AppBreadcrumb/index'

class DashboardLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            darktheme: true,
            isReport: false,
        };
        this.handleThemeSwitchButton = this.handleThemeSwitchButton.bind(this);
    }
    
    handleThemeSwitchButton () {
        const body = document.body
        body.classList.toggle('dark') 
        const bodyClass = document.body.className
        if(bodyClass === 'dark') {
            this.setState({
                darktheme: true
            });
        } else {
            this.setState({
                darktheme: false
            });
        }
    }

    

    render() {
        return (
            <>
                <ErrorBoundary>
                    <Toast />
                    <main className="tc-app">
                        <Sidebar 
                        />
                        <Content fluid>
                        <Spinner 
                            darktheme={this.state.darktheme}
                        />
                            <Header 
                                handleThemeSwitchButton={this.handleThemeSwitchButton}
                            />
                            <AppBreadcrumb />
                            <AppRouter
                                darktheme={this.state.darktheme}
                            />
                            <Footer />
                        </Content>
                    </main>
                </ErrorBoundary>
            </>
        )
    }
    
}

export default DashboardLayout;