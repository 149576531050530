import React from 'react'
import { connect } from 'react-redux'
import { Route } from 'react-router'
import Dashboard from 'components/Layout/Dashboard'
import CallbackPage from './components/CallbackPage'
import LoginPage from './components/LoginPage'
import './styles/tcApp.scss'
import { Interceptor } from './components/Controls'

/**
 * Configuración de usuarios
 */
const users = [
  {
    'username':'jose.galvez@trackandchain.com',
    'id':btoa('jose.galvez@trackandchain.com'),
    'clients':[
       '1000',
       '1001',
       '1004',
       '1005',
       '1006',
       '1007',
       '1008',
       '1009',
       '1010',
       '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1023'
    ]
 },
 {
    'username':'pedro.gilo@trackandchain.com',
    'id':btoa('pedro.gilo@trackandchain.com'),
    'clients':[
       '1000',
       '1001',
       '1004',
       '1005',
       '1006',
       '1007',
       '1008',
       '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1023'
    ]
    },
 {
    'username':'guillermo.martin@adiapack.com',
    'id':btoa('guillermo.martin@adiapack.com'),
    'clients':[
       '1000',
       '1001',
       '1004',
       '1005',
       '1006',
       '1007',
       '1008',
       '1009',
        '1010',
        '1011',
        '1012',
        '1013',
        '1014',
        '1015',
        '1016',
        '1017',
        '1018',
        '1019',
        '1020',
        '1021',
        '1022',
        '1023'
    ]
    },
{
    'username': 'ezcart@trackandchain.com',
    'id': btoa('ezcart@trackandchain.com'),
    'clients': [
        '1021'
    ]
},
{
    'username': 'ezcart2@trackandchain.com',
    'id': btoa('ezcart2@trackandchain.com'),
    'clients': [
        '1022'
    ]
},
{
    'username': 'dingoo@trackandchain.com',
    'id': btoa('dingoo@trackandchain.com'),
    'clients': [
        '1023'
    ]
},
]

const onInitialize = (user) => {

  if (sessionStorage.getItem('clients') === null) {
    sessionStorage.setItem('clients', '[]')
  }
  if (sessionStorage.getItem('devices') === null) {
    sessionStorage.setItem('devices', '[]')
  }
  if (sessionStorage.getItem('client') === null && localStorage.getItem('client') === null ) {
    sessionStorage.setItem('client', '-')
  }

  if (!user || user.expired) {
    sessionStorage.setItem('user.clients', '[]')
  }
  else {
    const _user = users.find(x => x.id === btoa(user.profile.unique_name))
    if (_user !== undefined || _user !== null) {
      const _userClients = JSON.stringify(_user.clients)
      sessionStorage.setItem('user.clients', _userClients)
      if ( localStorage.getItem('client') !== null ) {
        const selected = localStorage.getItem('client')
        sessionStorage.setItem('client', selected )
      } else  {
        sessionStorage.setItem('client', _user.clients.length > 0 ? _user.clients[0] : '-')
        localStorage.setItem('client', _user.clients.length > 0 ? _user.clients[0] : '-')
      }
    }
  }
}

const App = (props) => {

  const { user } = props

  onInitialize(user)

  const div =
    <>
      <LoginPage />
      <Route path="/callback" component={CallbackPage} />
    </>

  return !user || user.expired
    ? div
    : <>
      <Interceptor />
      <Dashboard />
    </>
}

const mapStateToProps = (state) => {
  return {
    user: state.oidc.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)