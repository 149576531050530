import React, {Component}  from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { getTimeFromDateTime } from '../../datetime'
import './index.css'

import { defaults } from 'react-chartjs-2'

class TempControl extends Component {

    componentDidMount() {
      if (this.props.darktheme === true) {
        defaults.global.defaultFontColor = '#dffffa'
      } else {
        defaults.global.defaultFontColor = '#2f6059'
      }

    }

    componentDidUpdate(prevProps) {
      if (this.props.darktheme !== prevProps.darktheme && this.props.darktheme === true) {
        defaults.global.defaultFontColor = '#dffffa';
      }
      
      if (this.props.darktheme !== prevProps.darktheme && this.props.darktheme === false) {
        defaults.global.defaultFontColor = '#2f6059';
      }

    }
    
    render() {
      const { 
          items, 
          darktheme
      } = this.props,
          labels = items.map(x => getTimeFromDateTime(x.date)),        
          values = items.map(x => x.value === -127 ? undefined : x.value)        
  
      const data = {
          labels: labels,
          datasets: [
            {
              label: 'Temperatura',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: values
            }
          ],
        }
  
      const optionsDark = {
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                  display: true,
                  color: '#88c0c0',
                  lineWidth: 0.5
                },
              }],
              yAxes: [{
                display: true,
                gridLines: {
                  display: true,
                  color: '#88c0c0',
                  lineWidth: 0.5
                },
              }]
        }
      }

          
      const optionsLight = {
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                  display: true,
                  color: '#88c0c0',
                  lineWidth: 1
                },
              }],
              yAxes: [{
                display: true,
                gridLines: {
                  display: true,
                  color: '#88c0c0',
                  lineWidth: 1
                },
              }]
        }
      }

      return (  
        <div className="temp-control">
          {items[0] !== undefined
           ? <>
            {darktheme === true
              ? <Line 
                data={data} 
                options={optionsDark}
                />
              : <Line 
                data={data} 
                options={optionsLight}
                />
              }
           </>
           : <div className="no-chart-card">
              <small>No se han encontrado registros de temperaturas</small>< br/>
          </div>
          }
          
        </div>      
      )
    }
    
}

TempControl.propTypes = {
    items: PropTypes.array.isRequired
}

TempControl.defaultValue = {
    items: []
}

export default TempControl;