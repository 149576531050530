import React from 'react'
import { Link } from 'react-router-dom'
import { TempControl } from '../index'
import { 
    Card, 
    CardHeader, 
    CardBody, 
    CardFooter 
} from 'reactstrap'

export default (props) => {
    const {
        title,
        items,
        itemsLoading,
        id,
        date,
        darktheme,
    } = props

    return (
            <Card className="black-card devices-card-wrapper tmsic-card">
                {itemsLoading
                    ? <>
                        <CardHeader className="card-header-link">
                            <Link to={`/containers/details/${btoa(id)}`}>
                                {title}
                            </Link>                
                        </CardHeader>
                        <CardBody className="tmsic-card-body">
                            <div className="no-chart-card">
                                <small className="loading-feedback">Cargando temperaturas ...</small>< br/>
                            </div> 
                            <small>Fecha: {date.toLocaleDateString()} | {date.toLocaleTimeString()}</small>< br/>
                        </CardBody>
                        <CardFooter>
                            <span className="hidden-content">footer</span>
                        </CardFooter>
                    </>
                    : <>
                        <CardHeader className="card-header-link">
                            <Link to={`/containers/details/${btoa(id)}`}>
                                {title}
                            </Link>                
                        </CardHeader>
                        <CardBody className="tmsic-card-body">
                            {id.includes('TMSIC-RPI') || id.includes('PMD')
                                ? <>
                                    <TempControl 
                                        items={items}
                                        darktheme={darktheme}
                                        ></TempControl>
                                    <small>DISPOSITIVO: {id}</small>
                                </>
                                : ''
                            }
                            {id.includes('CONTENEDOR SIN DISPOSITIVO')
                                ? <div className="no-chart-card">
                                    <small>{id}</small>< br/>
                                </div>
                                : ''
                            }
                            <small>Fecha: {date.toLocaleDateString()} | {date.toLocaleTimeString()}</small>
                        </CardBody>
                        <CardFooter>
                            <span className="hidden-content">footer</span>
                        </CardFooter>
                    </>
                }
            </Card>
        )
    
}