import React  from 'react'
import PropTypes from 'prop-types'
import GoogleMap from'../GoogleMap'
import Marker from'../Marker'
import './index.css'

const MarkersMap = (props) => {

  const { 
    markers, 
    center, 
    zoom,
    onClick
  } = props    
  
  return (
    <div className="map-container">
      <GoogleMap
        zoom={zoom}
        center={center}
        yesIWantToUseGoogleMapApiInternals
      >
         {markers.map(mark =>
          mark.latitude !== null
            ?
              <Marker
                key={`${mark.latitude}-${mark.longitude}-${mark.date}`}
                lat={mark.latitude}
                lng={mark.longitude}
                mark={mark}
                onClick={onClick}
              />
            : '' 
          )}
      </GoogleMap>
    </div>      
  )
}

MarkersMap.propTypes = {
  center: PropTypes.array.isRequired,
  markers: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired
}

MarkersMap.defaultValue = {
  center: [],
  markers: [],
  zoom: 16
}

export default MarkersMap