export const arrAvg = arr => arr.reduce((a,b)=> a+b, 0 ) / arr.length

export const getOptions = (label, dataset, color = 'rgba(75,192,192,1)', backgroundColor = 'rgba(75,192,192,0.4)') => {
    
    return {        
        showLine: true,
        label: label,        
         fill: true,
         lineTension: 0.1,        
         borderColor: color,
         backgroundColor: backgroundColor,
         cubicInterpolationMode: 'monotone',
         pointBorderWidth: 0.1,
         pointHoverRadius: 1,
         steppedLine:true,
         pointRadius: 0.01,
        data: dataset
      }
}

export  const detectSafariBrowser = () => {
  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari']);
  return isSafari
}