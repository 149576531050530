import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeviceContent from './content'

import {
    onInitializeHandler,
    getChangeCriteriaAction,
    onSearchHandler
} from './actions'

class DevicePage extends Component {

    componentDidMount() {

        const { id, initialize } = this.props

        initialize(id)
    }

    componentWillUnmount() { }

    render() {

        const {
            id,
            isLoading,
            startDate,
            endDate,
            startTime,
            endTime,
            onChange,
            onSearch,
            device,
            darktheme
        } = this.props

        const exportPdf = () => {
                const report = {
                    device,
                    startDate,
                    endDate,
                    startTime,
                    endTime
                }
                sessionStorage.setItem('report', JSON.stringify(report))
                window.open('/containers/report', '_blank')
            },
            exportExcel = () => {
                //window.open('/excel', '_blank')
            }

        return (
            <DeviceContent
                id={id}
                startTime={startTime}
                endTime={endTime}
                startDate={startDate}
                endDate={endDate}
                device={device}
                isLoading={isLoading}
                onSearch={onSearch}
                onChange={onChange}
                exportPdf={exportPdf}
                exportExcel={exportExcel}
                darktheme={darktheme}
            />
        )
    }
}

const mapStateToProps = (state, context) => {
    const current = state.DeviceReducer
    return {
        id: context.match.params.id,
        isLoading: current.isLoading,
        startDate: current.startDate,
        endDate: current.endDate,
        startTime: current.startTime,
        endTime: current.endTime,
        device: current.device
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initialize: (id) => dispatch(onInitializeHandler(id)),
        onChange: (value, source) => dispatch(getChangeCriteriaAction(value, source)),
        onSearch: () => dispatch(onSearchHandler())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicePage)