import React, { Component } from 'react'
import { FaFileExcel } from 'react-icons/fa'
import ReactExport from 'react-export-excel'
import './index.css'

const ExcelFile = ReactExport.ExcelFile,
    ExcelSheet = ReactExport.ExcelFile.ExcelSheet,
    ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ExcelReport extends Component {

    constructor() {

        super()

        const json = sessionStorage.getItem('report'),
            _report = JSON.parse(json)

        const orderByDate = (a, b) => {
            if (a.date > b.date)
                return 1
            if (b.date > a.date)
                return -1
            return 0
        }

        let values = []

        if (_report !== undefined && _report !== null
            && _report.device !== undefined && _report.device !== null
            && _report.device.values !== undefined && _report.device.values !== null
            && _report.device.values.length > 0) {
            values = _report.device.values.map(x => {
                let date = new Date(x.date.substring(0, 17) + '00.000Z')
                date.setHours(date.getHours() + _report.device.setup.time)
                return {
                    time: date.getTime(),
                    date: date,
                    value: x.payload.Temperature.toFixed(2)
                }
            }).sort(orderByDate)
        }

        this.state = {
            values: values
        }
    }

    componentDidMount() { }

    componentWillUnmount() { }

    render() {
        const _values = this.state.values,
            btn = <button className="btn btn-info">
                <FaFileExcel />
            </button>

        return (
            <ExcelFile element={btn}>
                <ExcelSheet data={_values} name="Registro">
                    <ExcelColumn label="Fecha" value="date" />
                    <ExcelColumn label="Temperatura" value="value" />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export default ExcelReport