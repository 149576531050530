import { TRUCK_ACTIONS } from './constants'
import { getStringDateTime, getCurrentTime } from '../datetime'

const INITIAL_STATE = {
    reference: '',    
    truck: {
        locations: [],
        path: [],
        temperatures: []
    },
    isLoading: true,
    startDate: getStringDateTime(),
    endDate: getStringDateTime(),
    startTime: getCurrentTime(-60),
    endTime: getCurrentTime(0)
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case TRUCK_ACTIONS.SET_START_DATE:
            return {
                ...state,
                startDate: action.value,
                isLoading: action.isLoading
            }

        case TRUCK_ACTIONS.SET_END_DATE:
            return {
                ...state,
                endDate: action.value,
                isLoading: action.isLoading
            }

        case TRUCK_ACTIONS.SET_START_TIME:
            return {
                ...state,
                startTime: action.value,
                isLoading: action.isLoading
            }

        case TRUCK_ACTIONS.SET_END_TIME:
            return {
                ...state,
                endTime: action.value,
                isLoading: action.isLoading
            }

        case TRUCK_ACTIONS.SET_IDENTITY:
            return {
                ...state,
                reference: action.reference,
                isLoading: action.isLoading,
                truck: action.truck,
                startDate: getStringDateTime(),
                endDate: getStringDateTime(),
                startTime: getCurrentTime(-60),
                endTime: getCurrentTime(0)
            }

        case TRUCK_ACTIONS.SET_LOADED:
            return {
                ...state,
                isLoading: action.isLoading,
                truck: action.truck
            }

        case TRUCK_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
                truck: action.truck
            }

        default:
            return state
    }
}