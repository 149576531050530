import {
    TRUCKS_ACTIONS,
    DEFAULT_ZOOM,
    DEFAULT_COORDS
} from './constants'

const INITIAL_STATE = {
    isLoading: true,
    trucks: [],    
    zoom: DEFAULT_ZOOM,
    center: DEFAULT_COORDS
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case TRUCKS_ACTIONS.SET_LOADING:
        case TRUCKS_ACTIONS.SET_LOADED:
            return {
                ...state,
                isLoading: action.isLoading,
                trucks: action.trucks,
                zoom: action.zoom,
                center: action.center
            }

        case TRUCKS_ACTIONS.SET_RELOAD:
            return {
                ...state,
                isLoading: action.isLoading,
                trucks: action.trucks
            }

        case TRUCKS_ACTIONS.SET_ZOOM:
            return {
                ...state,
                zoom: action.zoom,
                center: action.center
            }

        default:
            return state
    }
}