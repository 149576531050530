import React, { Component } from 'react'
import { connect } from 'react-redux'
import HomeContent from './content'
import {
    FaTruck,
    FaCubes,
    FaWarehouse
} from 'react-icons/fa'
import './index.css'

class HomePage extends Component {

    constructor() {
        super()

        this.state = {
            modules: []
        }
    }

    componentDidMount() {
        this.getModules()
    }
    
    componentWillUnmount() { }

    getModules(){

        const controls = [
            {
                name:'TRUCKS',
                title:'VEHÍCULOS',
                ico: <FaTruck />,
                text: 'Localización de la flota de transporte, temperatura y reporting...',
                link: '/trucks'
            },
            {
                name:'CONTAINERS',
                title:'CONTENEDORES',
                ico: <FaCubes />,
                text: 'Monitorización de contenedores y reporting de seguimiento',
                link: '/containers'
            },
            {
                name:'APS',
                title:'ALMACENES',
                ico: <FaWarehouse />,
                text: 'Realice el seguimiento de sus almacenes y estancias de preparación',
                link: ''
            }
        ]
        
        let clientId =  '',
            clients = [],
            client = '',
            modules = []

        setTimeout(()=> {

            clientId =  sessionStorage.getItem('client')
            clients = JSON.parse(sessionStorage.getItem('clients'))
            client = clients.find(x => x.clientId === clientId)

            if(client !== undefined){            
                modules = controls.filter(
                    x => client.modules.find(y => y === x.name) !== undefined
                )
            }
    
            this.setState({
                modules: modules
            })

        }, 300)
       

    }

    render() {
        
        return (
            <HomeContent
                modules={this.state.modules}
            />
        )
    }
}

HomePage.propTypes = {

}

HomePage.defaultProps = {

}

const mapStateToProps = (state) => {
    // const current = state.HomeReducer    
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)