import React from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import './index.css'

const TruckDetails = (props) => {

    const { truck } = props

    return (
        <div className="truck-details col-md-12 col-lg-7 col-xl-6">
            <Card className="black-card">
                <CardHeader>
                    DETALLES DEL VEHÍCULO
                </CardHeader>
                <CardBody className="responsive-page-table-container">

                    {truck !== undefined
                        ? <> 
                            <h6 className="truck-details-table-title">
                                    {`Vehículo: ${truck.boxId}`}
                                </h6>
                                <table className="table table-striped small dark-table responsive-page-table">
                                    <thead>
                                        <tr>
                                            <th>Proveedor</th>
                                            <th>Matrícula</th>
                                            <th>Dispositivo</th>                         
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-th="Proveedor">{truck.company}</td>
                                            <td data-th="Matrícula">{truck.reference}</td>
                                            <td data-th="Dispositivo">{truck.logicalId}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        : ''
                    }

                    { truck.sensorInfo && truck.sensorInfo[0] !== undefined
                        ? <>
                            <h6 className="truck-details-table-title">
                                Dispositivos medición térmica en áreas de la caja del vehículo
                            </h6>
                            <table className="table table-striped small dark-table truck-details-devices-table">
                                <thead>
                                    <tr>
                                        {truck.sensorInfo.map( sensor => 
                                                <th key={sensor.logicalId}>{sensor.name}</th>
                                        )}                            
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        {truck.sensorInfo.map( sensor => 
                                                <td key={sensor.logicalId}>
                                                    <span>{sensor.logicalId}</span>
                                                </td>
                                            ) 
                                        }
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        : ''
                    }
                    
                </CardBody>
                <CardFooter>
                    <span className="hidden-content">
                        FOOTER
                    </span>
                </CardFooter>
            </Card>
        </div>
    )
}

TruckDetails.propTypes = {
    truck: PropTypes.object.isRequired
}

export default TruckDetails