import { DEVICES_ACTIONS } from './constants';

const INITIAL_STATE = {
    isLoading: true,
    devices: []
}

export default (state =  INITIAL_STATE, action) => {
    switch(action.type){

        case DEVICES_ACTIONS.SET_LOADING:
        case DEVICES_ACTIONS.SET_LOADED:
            return {
                ...state,
                isLoading: action.isLoading,
                devices: action.devices
            }

        default:
            return state
    }
}