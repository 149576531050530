import React from 'react'
import PropTypes from 'prop-types'
import { RouteMap } from '../Controls'

import DetailsFilter from '../Controls/DetailsFilter/index'
import TruckDetails from '../Controls/TruckDetails/index'
import TruckTemperatures from '../Controls/TruckTemperatures/index'


import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import { getLocationsTable } from './helpers'


const center = [40.4167, -3.70325]

const Content = (props) => {

    const {
        startDate,
        startTime,
        endDate,
        endTime,
        isLoading,
        onSearch,
        onChange,
        exportPdf,
        exportExcel,
        truck,
        darktheme
    } = props

    if (truck.locations === undefined)
        return 'Cargando'

    return (
        <div className="container-fluid page-content-wrapper">
            <div className="row">

                <DetailsFilter
                    startDate={startDate}
                    startTime={startTime}
                    endDate={endDate}
                    endTime={endTime}
                    onSearch={onSearch}
                    onChange={onChange}
                    exportPdf={exportPdf}
                    exportExcel={exportExcel}
                    isLoading={isLoading}
                    exports={false}
                />
                <TruckDetails truck={truck} />

            </div>


            <div className="whitespace"></div>

            <div className="row">
                <div className="col-md-12 col-lg-7 col xl-9">
                    <Card className="black-card">
                        <CardHeader>
                            Mapa recorrido
                        </CardHeader>
                        <CardBody>
                            { truck.path[0]
                                ? <RouteMap
                                    path={truck.path}
                                    center={center}
                                />
                                : <p>No se ha realizado ningún recorrido en la franja horaria seleccionada</p>
                            }
                            
                        </CardBody>
                        <CardFooter>
                            <span className="hidden-content">
                                FOOTER
                            </span>
                        </CardFooter>
                    </Card>
                </div>

                <div className="col-md-12 col-lg-5 col xl-3">
                    <Card className="black-card">
                        <CardHeader>
                            REGISTRO DE LOCALIZACIÓN
                        </CardHeader>
                        <CardBody>
                            {
                                getLocationsTable(truck)
                            }
                        </CardBody>
                        <CardFooter>
                            <span className="hidden-content">
                                FOOTER
                            </span>
                        </CardFooter>
                    </Card>
                </div>

            </div>

            <div className="whitespace"></div>

            <TruckTemperatures
                truck={truck}
                darktheme={darktheme}
            />
            
            <div className="whitespace"></div>


        </div>
    )
}

Content.propTyes = {
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    truck: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
}

Content.defaultValues = {
    truck: {}
}

export default Content