import React from 'react'
import { Card, CardBody, CardTitle, CardText, CardHeader, CardFooter } from 'reactstrap'
import { Link } from 'react-router-dom'
import { detectSafariBrowser } from '../transversal'

const HomeContent = (props) => {

    const { modules } = props

    return (
        <div className="container-fluid page-content-wrapper">
            <div className="hero-container">
                {detectSafariBrowser
                ? <video playsInline autoPlay loop muted className="hero-video">
                    <source src="./assets/video/hero-track-and-chain.mp4" type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>
                : <video autoPlay loop muted className="hero-video" >
                        <source src="./assets/video/hero-track-and-chain.mp4" type="video/mp4" />
                        Your browser does not support HTML5 video.
                </video>
                }
                <div className="hero-image" >
                </div>
                <div className="hero-overlay">
                    <h1 className="hero-title">
                        Banco de pruebas IOT Dashboard
                    </h1>
                    <h2 className="hero-title">
                        Track &amp; Chain
                    </h2>
                    <h3 className="hero-claim">
                        Driving innovation through data
                    </h3>
                    <p className="hero-link">
                        <a href="https://trackandchain.com" rel="noopener noreferrer" target="_blank">
                            https://trackandchain.com
                        </a>
                    </p>
                </div>
            </div>

            <div className="whitespace"></div>

                <Card className="black-card">
                    <CardHeader>
                        MÓDULOS ACTIVOS
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            {modules ?
                                modules.map((x, i) => {
                                    return (
                                        <div className="col-md-4 col-lg-4 col-xl-3" key={i}>
                                            <Card 
                                                className="black-card module-card-container"
                                                data-aos="fade-down">
                                                    <CardHeader>
                                                        {x.title}
                                                    </CardHeader>
                                                    <Link
                                                    to={x.link}>
                                                        <CardBody className="module-card">
                                                            <CardTitle 
                                                                className="module-title">
                                                                {x.ico}

                                                            </CardTitle>
                                                            
                                                            <CardText>
                                                                {x.text}
                                                            </CardText>

                                                        </CardBody>
                                                    </Link>
                                                    <CardFooter>
                                                        <Link
                                                            to={x.link}
                                                            className="btn btn-primary">
                                                            Acceder
                                                        </Link>
                                                    </CardFooter>

                                            </Card>
                                        </div>
                                    )
                                })
                                : ''
                            }
                        </div>
                    </CardBody>
                    {/* <CardFooter>
                        <span className="hidden-content">
                            FOOTER
                        </span>
                    </CardFooter> */}
                </Card>

            <div className="whitespace"></div>

        </div>
    )
}

HomeContent.propTypes = {

}

HomeContent.defaultValues = {

}

export default HomeContent