import React from 'react'
import { Navbar } from 'reactstrap'
import { Link } from 'react-router-dom'
import './index.css'

export default () => {
    return (
        <div className="app-brand">
            <Navbar>
                <Link to={'/'}>
                    <img
                        src="/assets/images/icons/tc-pwa-icon-5-64.png"
                        className="pr-2"
                        alt="Track & Chain Dashboard icon"
                    />
                    <span className="app-brand-text">
                        IOT DASHBOARD
                    </span>
                    <p className="app-brand-text app-brand-text-second-text">
                        BANCO DE PRUEBAS
                    </p>
                </Link>
            </Navbar>
        </div>
    )
}