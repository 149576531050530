export const TRUCKS_ACTIONS = {
    SET_LOADING: 'TRUCKS_SET_LOADING',
    SET_LOADED: 'TRUCKS_SET_LOADED',
    SET_RELOAD: 'TRUCKS_SET_RELOAD',
    SET_ZOOM: 'TRUCKS_SET_ZOOM',
}

// COORDENADAS MADRID CENTRO
export const DEFAULT_COORDS = [ 40.4167, -3.70325 ]
// ZOOM POR DEFECTO
export const DEFAULT_ZOOM = 10
// ZOOM DEL MAPA DE UN VEHÍCULO CONCRETO
export const DEFAULT_TRUCK_ZOOM = 16