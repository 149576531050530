import React from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import { TempControl } from '../index'
import { getTemperatureTable } from '../../TruckPage/helpers'

import './index.css'


const TruckTemperatures = ({ truck, darktheme }) => {

    if(truck.temperatures && truck.temperatures[0] !== undefined) {

        return (
                <Card className="black-card truck-temperature-areas-wraper">

                    <CardHeader>
                        REGISTRO DE TEMPERATURA
                    </CardHeader>

                    <CardBody>
                        <div className="truck-temperature-area-laptop-row">

                            { truck.temperatures[0].sensorValues.length > 0 
                                ? truck.temperatures.map( x => 
                                    <div className="truck-temperature-area-main-container" key={x.info.logicalId}>
                                        <div className="truck-temperature-area-container">
                                            <Card className="black-card">
                                                <CardHeader>
                                                    {`Temperatura ${x.info.name}`}
                                                </CardHeader>
                                                <CardBody>
                                                    {<TempControl 
                                                    items={x.sensorValues} 
                                                    darktheme={darktheme}/>
                                                    }
                                                </CardBody>
                                                <CardFooter>
                                                    <span className="hidden-content">
                                                        FOOTER
                                                    </span>
                                                </CardFooter>
                                            </Card>
                                            <Card className="black-card">
                                                <CardHeader>
                                                    {`Registro temperaturas ${x.info.name}`}
                                                </CardHeader>
                                                <CardBody>
                                                    { x.sensorValues.length > 0 
                                                        ? getTemperatureTable(x)
                                                        : <table className="dark-table table table-bordered table-striped small">
                                                            <thead>
                                                                <tr>
                                                                    <th>Fecha</th>
                                                                    <th>Temperatura</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        No se han encontrado registros de temperaturas
                                                                </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colSpan="2">
                                                                        <span className="hidden-content">
                                                                            FOOTER
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    }
                                                </CardBody>
                                                <CardFooter>
                                                    <span className="hidden-content">
                                                        FOOTER
                                                    </span>
                                                </CardFooter>
                                            </Card>
                                        </div>
                                        <div className="whitespace"></div>
                                    </div>
                                )
                                : <p>No hay registros de temperaturas en la franja horaria seleccionada</p>
                            }        
                        </div>
                    <div className="whitespace"></div>
                    </CardBody>
                </Card>
        
            );
    } else {
        return ''
    }

    
};

export default TruckTemperatures;