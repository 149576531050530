import React from 'react'
import { connect } from 'react-redux'
import { CallbackComponent } from 'redux-oidc'
import { push } from 'connected-react-router'
import userManager from '../../userManager'
import AppSettings from '../AppSettings'
import './index.css'

const onSuccessHandler = (user, dispatch) => {
    const url = process.env.REACT_APP_CLIENTS_API_URL
    fetch(url)
        .then(response => response.json())
        .then(clients => {
            // TODO: La API de clientes debe devolver solo los clientes del usuario concreto
            const _clients = JSON.parse(sessionStorage.getItem('user.clients'))
            clients = clients.filter(x => _clients.indexOf(x.clientId) > -1)
            let selected = sessionStorage.getItem('client')
            if (selected === undefined || selected === null || selected === '-') {
                selected = (clients.length > 0) ? clients[0].clientId : '-'
            }

            // TODO: modificar para obtener los dispositivos de un servicio web
            const settings = new AppSettings(),
                devices = settings.getIds(selected)
            sessionStorage.setItem('clients', JSON.stringify(clients))
            sessionStorage.setItem('devices', JSON.stringify(devices))
            sessionStorage.setItem('client', selected)
            localStorage.setItem('client', selected)
            
            dispatch({
                type:'CLIENT_SELECTED',
                selected: selected
            })
            dispatch(push('/'))            
        })
}

const onErrorHandler = (err, dispatch) => {
    dispatch(push('/'))
}

const mapStateToProps = (state) => {
    return {
        userManager: userManager
    }
}

const mapDispatchToProps = dispatch => {
    return {
        successCallback: (user) => onSuccessHandler(user, dispatch),
        errorCallback: (error) => onErrorHandler(error, dispatch)
    }
}

const CallbackPage = (props) => {

    const {
        userManager,
        successCallback,
        errorCallback
    } = props

    return (
        <CallbackComponent
            userManager={userManager}
            successCallback={successCallback}
            errorCallback={errorCallback}
        >
            <div>Redirecting...</div>
        </CallbackComponent>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage)