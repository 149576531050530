import React, { Component } from 'react'
import { connect } from 'react-redux'
import Content from './content'

import {
    onInitializeHandler,
    getChangeCriteriaAction,
    onSearchHandler
} from './actions'

import './index.css'

class TruckPage extends Component {

    componentDidMount() {

        const { reference, initialize } = this.props

        initialize(reference)
    }

    componentWillUnmount() { }

    render() {

        const {
            isLoading,
            startDate,
            endDate,
            startTime,
            endTime,
            onChange,
            onSearch,
            truck,
            darktheme
        } = this.props

        const exportPdf = () => {},
            exportExcel = () => {}

        return (
            <Content                
                startTime={startTime}
                endTime={endTime}
                startDate={startDate}
                endDate={endDate}
                truck={truck}
                isLoading={isLoading}
                onSearch={onSearch}
                onChange={onChange}
                exportPdf={exportPdf}
                exportExcel={exportExcel}
                darktheme={darktheme}
            />
        )
    }
}

const mapStateToProps = (state, context) => {
    const current = state.TruckReducer
    return {
        reference: context.match.params.reference,
        isLoading: current.isLoading,
        startDate: current.startDate,
        endDate: current.endDate,
        startTime: current.startTime,
        endTime: current.endTime,
        truck: current.truck
    }
}

const mapDispatchToProps = dispatch => {
    return {
        initialize: (reference) => dispatch(onInitializeHandler(reference)),
        onChange: (value, source) => dispatch(getChangeCriteriaAction(value, source)),
        onSearch: () => dispatch(onSearchHandler())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckPage)