import {
    TRUCKS_ACTIONS,
    DEFAULT_COORDS,
    DEFAULT_ZOOM,
    DEFAULT_TRUCK_ZOOM
} from './constants'

import { arrAvg } from '../transversal'

/**
 * Obtiene la colección de vehículos y si información de seguimiento
 * @param {Object} response Referencia al objeto de respuesta de la petición http 
 * @param {Array} devices Referencia a la colección de definición de dispositivos original
 * @returns {Array} Referencia a la información de seguimiento de los vehículos
 */
const getTrucksFromResponse = (response, devices) => {
    const _devices = response.devices.filter(x => x.values !== undefined && x.values.length > 0)
    return _devices.map(x => {
        const location = x.values[0].payload.Location,
            _device = devices.find(y => y.logicalId === x.logicalId)
        //debugger

        return (location === undefined)
            ? {
                ..._device,
                latitude: null,
                longitude: null,
                date: null
            }
            : {
                ..._device,
                latitude: location.Latitude,
                longitude: location.Longitude,
                date: location.CreatedDate
            }
    })
}

/**
 * Obtiene las coordenadas para centrar el mapa en función de los vehículos activos
 * @param {Array} trucks Colección de información de vehículos
 * @returns {Array} Cooredenadas con formato array [lat, lng]
 */
const getCenterFromTrucks = (trucks) => {

    if (trucks.length === 0) {
        return DEFAULT_COORDS
    }

    const lat = arrAvg(trucks.filter(x=>x.latitude !== null).map(x => x.latitude)),
        lng = arrAvg(trucks.filter(x=> x.longitude !== null).map(x => x.longitude))

    return [lat, lng]
}

/**
 * Obtiene el nivel de zoom a establecer en función del número de vehículos
 * @param {Array} trucks Colección de información de vehículos
 * @return {Number} Zoom
 */
const getZoomFromTrucks = (trucks) => {
    return DEFAULT_ZOOM
}

/**
 * Obtiene la colección de dispositivos a representar
 * @returns {Function} Referencia a la función de carga
 */
export const getTrucks = () => {
    return (dispatch) => {
        const devices = JSON.parse(sessionStorage.getItem('devices'))
        let ids="",
            uri=""

        for ( const device of devices ) {

            if ( device.logicalId.includes('TMSIC-HUBMOCK-') ) {
                ids = devices
                    .filter(x => x.logicalId.indexOf('TMSIC-HUBMOCK-') > -1)
                    .map(x => x.logicalId)
                    .join(',')
                uri = '/data/mock-trucks.json'
            } 
            
            if ( device.logicalId.includes('TMSIC-HUB-') ){
                ids = devices
                    .filter(x => x.logicalId.indexOf('TMSIC-HUB-') > -1)
                    .map(x => x.logicalId)
                    .join(',')
                uri = `${process.env.REACT_APP_LAST_DATA_URL}?ids=${ids}`
            }

        } 


        if (ids.length < 1) {
            dispatch({
                type: TRUCKS_ACTIONS.SET_LOADED,
                trucks: [],
                center: DEFAULT_COORDS,
                zoom: DEFAULT_ZOOM,
                isLoading: false
            })
            return
        }

        return fetch(uri)
            .then(httpResponse => httpResponse.json())
            .then(response => {
                const trucks = getTrucksFromResponse(response, devices),
                    center = getCenterFromTrucks(trucks),
                    zoom = getZoomFromTrucks(trucks)
                dispatch({
                    type: TRUCKS_ACTIONS.SET_LOADED,
                    trucks: trucks,
                    center: center,
                    zoom: zoom,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

/**
 * Obtiene la acción de carga de la página
 * @returns {Object} Referencia a los parámetros de acción
 */
export const setLoading = () => {
    return {
        type: TRUCKS_ACTIONS.SET_LOADING,
        trucks: [],
        isLoading: true,
        zoom: DEFAULT_ZOOM,
        center: DEFAULT_COORDS
    }
}

/**
 * Recarga la información de los vehículos en cuanto a su posición
 * @returns {Function} Referencia a la función de carga
 */
export const reloadTrucks = () => {
    return (dispatch) => {
        const devices = JSON.parse(sessionStorage.getItem('devices'))
        let ids="",
            uri=""

        for ( const device of devices ) {

            if ( device.logicalId.includes('TMSIC-HUBMOCK-') ) {
                ids = devices
                    .filter(x => x.logicalId.indexOf('TMSIC-HUBMOCK-') > -1)
                    .map(x => x.logicalId)
                    .join(',')
                uri = '/data/mock-trucks.json'
            } 
            
            if ( device.logicalId.includes('TMSIC-HUB-') ){
                ids = devices
                    .filter(x => x.logicalId.indexOf('TMSIC-HUB-') > -1)
                    .map(x => x.logicalId)
                    .join(',')
                uri = `${process.env.REACT_APP_LAST_DATA_URL}?ids=${ids}`
            }

        } 

        return fetch(uri)
            .then(httpResponse => httpResponse.json())
            .then(response => {
                const trucks = getTrucksFromResponse(response, devices)
                dispatch({
                    type: TRUCKS_ACTIONS.SET_RELOAD,
                    trucks: trucks,
                    isLoading: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

/**
 * Establece el vehículo en el que el mapa debe centrarse
 * @param {Object} truck Referencia al vehículo a centrarse
 * @returns {Object} Referencia a los parámetros de la acción
 */
export const setZoom = (truck) => {
    return (dispatch, getState) => {

        const state = getState().TrucksReducer,
            _center = state.center,
            center = [truck.latitude, truck.longitude]

        dispatch({
            type: TRUCKS_ACTIONS.SET_ZOOM,
            truck: truck,
            center: (_center[0] === center[0] && _center[1] === center[1]) ? [truck.latitude * 1.000001, truck.longitude] : center,
            zoom: DEFAULT_TRUCK_ZOOM
        })
    }
}