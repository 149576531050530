import React from 'react'
import PropTypes from 'prop-types'
import { TmsicRpiCard } from '../Controls'

const Content = (props) => {
    
    const { devices, darktheme } = props

    const getView = (device) => {
        if ((device.logicalId.indexOf('TMSIC-RPI') > -1) || (device.logicalId.indexOf('PMD') > -1)) {
            return <TmsicRpiCard 
                        id={device.logicalId} 
                        window={device.window}
                        title={device.boxId}
                        setup={device.setup}
                        darktheme={darktheme}
                   />
        }

        if (device.logicalId.indexOf('CONTENEDOR SIN DISPOSITIVO') > -1 ) {
            return <TmsicRpiCard 
                        id={device.logicalId} 
                        window={device.window}
                        title={device.boxId}
                        setup={device.setup}
                        darktheme={darktheme}
                   />
        }

        return ''
    }

    return (
        <div className="container-fluid page-content-wrapper">
            <div className="row">
                {devices.map(x => {
                    return (
                        <div key={x.logicalId} className="col-md-12 col-lg-4">
                            {getView(x)}
                            <div className="whitespace"></div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

Content.propTypes = {
    devices: PropTypes.array.isRequired
}

Content.defaultValues = {
    devices: []
}

export default Content