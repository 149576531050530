export const ERRORS_ACTIONS = {    
    SET_ERROR: 'SET_ERROR'    
}

export const DEFAULT_ERROR = {
    title: '',
    subtitle: '',
    description: '',
    details: ''
}

export const URLS = {
    errorPage: '/error'
}