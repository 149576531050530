import React from 'react'
import PropTypes from 'prop-types'
import {
    TmsicRpi,
    DetailsFilter,
    DeviceDetails
} from '../Controls'

const DeviceContent = (props) => {
    const {
        startDate,
        startTime,
        endDate,
        endTime,
        device,
        isLoading,
        onSearch,
        onChange,
        exportPdf,
        exportExcel,
        darktheme
    } = props

    const getView = (device) => {
        if (device.logicalId !== undefined && ((device.logicalId.indexOf('TMSIC-RPI') > -1) || (device.logicalId.indexOf('PMD') > -1))) {
            return <TmsicRpi 
                    device={device} 
                    darktheme={darktheme}/>
        }
        return ''
    }

    return (
        <div className="container-fluid page-content-wrapper">
            {isLoading 
            
            ? <p className="loading-feedback">Cargando...</p>
            
            : <>

            <div className="row">
                <DetailsFilter
                    startDate={startDate}
                    startTime={startTime}
                    endDate={endDate}
                    endTime={endTime}
                    onSearch={onSearch}
                    onChange={onChange}
                    exportPdf={exportPdf}
                    exportExcel={exportExcel}
                    isLoading={isLoading}
                    device={device}
                />
                
                <DeviceDetails device={device} />
            </div>


            <div className="whitespace"></div>

            {getView(device)}
            </>
        }

        </div>
    )
}

DeviceContent.propTyes = {
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    device: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
}

DeviceContent.defaultValues = {
    device: {}
}

export default DeviceContent