import { DEVICE_ACTIONS } from './constants'
import { getStringDateTime, getCurrentTime } from '../datetime'

const INITIAL_STATE = {
    id: '',
    device: {
        logicalId: '',
        boxId: '',
        window: 60,
        setup: {
            step: 120,
            refresh: 900,
            time: -1
        }
    },
    isLoading: true,
    startDate: getStringDateTime(),
    endDate: getStringDateTime(),
    startTime: getCurrentTime(-60),
    endTime: getCurrentTime(0)
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case DEVICE_ACTIONS.SET_START_DATE:
            return {
                ...state,
                startDate: action.value,
                isLoading: action.isLoading
            }

        case DEVICE_ACTIONS.SET_END_DATE:
            return {
                ...state,
                endDate: action.value,
                isLoading: action.isLoading
            }

        case DEVICE_ACTIONS.SET_START_TIME:
            return {
                ...state,
                startTime: action.value,
                isLoading: action.isLoading
            }

        case DEVICE_ACTIONS.SET_END_TIME:
            return {
                ...state,
                endTime: action.value,
                isLoading: action.isLoading
            }

        case DEVICE_ACTIONS.SET_IDENTITY:
            return {
                ...state,
                id: action.id,
                isLoading: action.isLoading,
                startDate: action.startDate,
                endDate: action.endDate,
                startTime: action.startTime,
                endTime: action.endTime
            }

        case DEVICE_ACTIONS.SET_LOADED:
            return {
                ...state,
                isLoading: action.isLoading,
                device: action.device
            }

        case DEVICE_ACTIONS.SET_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
                device: action.device
            }

        default:
            return state;
    }
}