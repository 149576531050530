import React from 'react'
import { connect } from 'react-redux'
import './index.css'

const SpinnerComponent = ({ show, darktheme }) => {

  const tag = 
    <div className="cr-page-spinner">
      <div className="preloader">
        <div className="preloader__square">
          <i className="fas fa-box">
          </i>
        </div>
        <div className="preloader__square">
          <i className="fas fa-store"></i>
        </div>
        <div className="preloader__square">
          <i className="fas fa-truck"></i>
        </div>
        <div className="preloader__square">
          {darktheme
          ? <img 
            className="delivery-man"
            src="/assets/images/icons/delivery-man-white-48px.png"
            alt="delivery man icon"
            ></img>
          :<img 
            className="delivery-man"
            src="/assets/images/icons/delivery-man-green.png"
            alt="delivery man icon"
            ></img>
          }
        </div>
      </div>

    </div>

  return (
    <>
      {show ? tag : ''}
    </>
  )
}

const mapStateToProps = (state) => {

  const current = state.SpinnerReducer;
    
  return {
    show: current.show,
  }
    
}

export default connect(mapStateToProps)(SpinnerComponent);
