class AppSettings {

    getEventsUrl = () => {
        return process.env.REACT_APP_EVENTS_API_URL
    }

    getAlertsUrl = () => {
        return process.env.REACT_APP_ALERTS_API_URL;
    }

    getLastDataUrl = () => {
        return process.env.REACT_APP_LAST_DATA_URL;
    }

    getDeviceUrl = () => {
        return process.env.REACT_APP_DATA_API_URL;
    }

    getIds = (clientId) => {
        let device = [
            // ADIAPACK
            { boxId: ' B963', logicalId: 'TMSIC-RPI-BCFF4DC0B963', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: ' B960', logicalId: 'TMSIC-RPI-BCFF4DC0B960', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: ' B94B', logicalId: 'TMSIC-RPI-BCFF4DC0B94B', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: ' B949', logicalId: 'TMSIC-RPI-BCFF4DC0B949', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'DAFD', logicalId: 'TMSIC-RPI-50029171DAFD', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: '83D8', logicalId: 'TMSIC-RPI-ECFABC7E83D8', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'dev 90/12seg', logicalId: 'TMSIC-RPI-50029171DA7F', clientId: '1000', window: 60, setup: { step: 12, refresh: 90, time: 0 } },
            { boxId: ' guillermo1', logicalId: 'TMSIC-RPI-C8C9A389982C', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: ' guillermo2', logicalId: 'TMSIC-RPI-C8C9A3858449', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: ' guillermo3', logicalId: 'TMSIC-RPI-4855196B967A', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'pmd1', logicalId: 'PMD-5CCF7FEFB03F', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'pmd2', logicalId: 'PMD-4827E254A9C2', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },  
            { boxId: 'pmd_dev', logicalId: 'PMD-84F703D79AC6', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } },  
            { boxId: 'pmd_demo_dominos', logicalId: 'PMD-4827E254A9C2', clientId: '1000', window: 60, setup: { step: 150, refresh: 900, time: 0 } }, 
            
            // ADIAPACK-TRANSPORTE DEMO  
            //hubs pendiente de validar para ECI
            {
                boxId: 'VEHÍCULO 1',
                reference: '479454',
                company: 'Adiabat',
                route: '1', driver: '1',
                phone: '000',
                sensorInfo: [
                    {
                        name: 'Área ambiente',
                        logicalId: 'TMSIC-RPI-84F3EBD20807'
                    },
                ],
                logicalId: 'TMSIC-HUB-479454',
                clientId: '1000', window: 60,
                setup: {
                    step: 150,
                    refresh: 900,
                    time: 0
                }
            },

            //EZCART Cascais

            { boxId: 'ZA01/T09-4863', logicalId: 'TMSIC-RPI-483FDAADDE4F', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA121/T09-4864', logicalId: 'TMSIC-RPI-483FDAAE00D2', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA114/T09-4865', logicalId: 'TMSIC-RPI-483FDAAD534B', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA04/T09-4866', logicalId: 'TMSIC-RPI-BCFF4DC66771', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA05/T09-4867', logicalId: 'TMSIC-RPI-483FDAAE00F3', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA06/T09-4868', logicalId: 'TMSIC-RPI-ECFABCDB227A', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA117/T09-4870', logicalId: 'TMSIC-RPI-BCFF4DE3A4D5', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA112/T09-4871', logicalId: 'TMSIC-RPI-ECFABCDB20DD', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA127/T09-4872', logicalId: 'TMSIC-RPI-ECFABCDB2432', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA123/T09-4873', logicalId: 'TMSIC-RPI-BCFF4DE3B305', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA11/T09-4874', logicalId: 'TMSIC-RPI-BCFF4DE3B34F', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA104/T09-4876', logicalId: 'TMSIC-RPI-ECFABCDB1C5B', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA13/T09-4877', logicalId: 'TMSIC-RPI-483FDAADDB76', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA14/T09-4878', logicalId: 'TMSIC-RPI-483FDAAE0132', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA15/T09-4886', logicalId: 'TMSIC-RPI-483FDAADFCA2', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA118/T09-4887', logicalId: 'TMSIC-RPI-BCFF4DE38BB6', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA110/T09-4888', logicalId: 'TMSIC-RPI-ECFABCDB2350', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA128/T09-4889', logicalId: 'TMSIC-RPI-483FDAADFC96', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA19/T09-4890', logicalId: 'TMSIC-RPI-483FDAADDB36', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA113/T09-4891', logicalId: 'TMSIC-RPI-483FDAADD752', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA21/T09-4892', logicalId: 'TMSIC-RPI-483FDAADD787', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA107/T09-4893', logicalId: 'TMSIC-RPI-483FDAAD93DA', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA23/T09-4894', logicalId: 'TMSIC-RPI-BCFF4DC62F0D', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA116/T09-4895', logicalId: 'TMSIC-RPI-BCFF4DC0B6BF', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA109/T09-4896', logicalId: 'TMSIC-RPI-BCFF4DC66010', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA26/T09-4897', logicalId: 'TMSIC-RPI-BCFF4DE3B316', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA27/T09-4898', logicalId: 'TMSIC-RPI-BCFF4DE3A1D9', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA158/T09-4900', logicalId: 'TMSIC-RPI-483FDAAD5459', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA122/T09-4901', logicalId: 'TMSIC-RPI-483FDAAE0487', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA126/T09-4902', logicalId: 'TMSIC-RPI-483FDAAE00FA', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA31', logicalId: 'TMSIC-RPI-ECFABC7E82AB', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA32', logicalId: 'TMSIC-RPI-D8BFC00375C5', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA33', logicalId: 'TMSIC-RPI-D8BFC00375D0', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA34', logicalId: 'TMSIC-RPI-ECFABC7E82C8', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA35', logicalId: 'TMSIC-RPI-D8BFC00375C8', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA36', logicalId: 'TMSIC-RPI-D8BFC00375B7', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA37', logicalId: 'TMSIC-RPI-50029171D531', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA38', logicalId: 'TMSIC-RPI-ECFABC7E82C3', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA39', logicalId: 'TMSIC-RPI-50029171D7E6', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA40', logicalId: 'TMSIC-RPI-D8BFC00371BB', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA103/T09-5193', logicalId: 'TMSIC-RPI-BCFF4DE359F9', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA42/T09-5042', logicalId: 'TMSIC-RPI-BCFF4DE361DD', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA101/T09-5196', logicalId: 'TMSIC-RPI-BCFF4DE3574A', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA105/T09-5197', logicalId: 'TMSIC-RPI-BCFF4DE3A218', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA45/T09-5048', logicalId: 'TMSIC-RPI-BCFF4DC61CDD', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA124/T09-5049', logicalId: 'TMSIC-RPI-BCFF4DC0B973', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA108/T09-5053', logicalId: 'TMSIC-RPI-BCFF4DC0B55A', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA48/T09-5052', logicalId: 'TMSIC-RPI-BCFF4DC67066', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA49/T09-5050', logicalId: 'TMSIC-RPI-BCFF4DC0B554', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA119/T09-5051', logicalId: 'TMSIC-RPI-BCFF4DC0B98F', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'Frio negativo', logicalId: 'TMSIC-RPI-483FDAAD5316', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },

            //nuevo pedido Sept 23 para otra prueba piloto
            { boxId: 'ZA146/T09-6408', logicalId: 'TMSIC-RPI-7C87CEA96F09', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA145/T09-6407', logicalId: 'TMSIC-RPI-BCFF4DE3B5BF', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA144/T09-6406', logicalId: 'TMSIC-RPI-7C87CEA9E2A8', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA148/T09-6405', logicalId: 'TMSIC-RPI-FA4E57C2D6E8', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA151/T09-6404', logicalId: 'TMSIC-RPI-A4E57C2CC428', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA150/T09-6403', logicalId: 'TMSIC-RPI-7C87CEAA24B7', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA149/T09-6402', logicalId: 'TMSIC-RPI-7C87CEAA1199', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA153/T09-6401', logicalId: 'TMSIC-RPI-BCFF4DE3A271', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA155/T09-6399', logicalId: 'TMSIC-RPI-BCFF4DC0B665', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA154/T09-6400', logicalId: 'TMSIC-RPI-BCFF4DC0B879', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA106/T09-6419', logicalId: 'TMSIC-RPI-A4E57C2D8042', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA157/T09-6420', logicalId: 'TMSIC-RPI-BCFF4DC61D51', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA156/T09-6418', logicalId: 'TMSIC-RPI-7C87CEAA4E04', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA141/T09-6417', logicalId: 'TMSIC-RPI-A4E57C2D9977', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA140/T09-6416', logicalId: 'TMSIC-RPI-4855196B9AC9', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA139/T09-6415', logicalId: 'TMSIC-RPI-BCFF4DC0B6FD', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA143/T09-6414', logicalId: 'TMSIC-RPI-BCFF4DC6694D', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA147/T09-6413', logicalId: 'TMSIC-RPI-BCFF4DC0B880', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA152/T09-6411', logicalId: 'TMSIC-RPI-A4E57C2CC174', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA142/T09-6412', logicalId: 'TMSIC-RPI-BCFF4DC0B89D', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA129/T09-6409', logicalId: 'TMSIC-RPI-A4E57C2DA54E', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA130/T09-6410', logicalId: 'TMSIC-RPI-BCFF4DC6709F', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA133/T09-6361', logicalId: 'TMSIC-RPI-7C87CEAA381B', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA132/T09-6369', logicalId: 'TMSIC-RPI-BCFF4DC0B58E', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA131/T09-6372', logicalId: 'TMSIC-RPI-7C87CEA9749C', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA135/T09-6370', logicalId: 'TMSIC-RPI-A4E57C2CFCD1', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA134/T09-6398', logicalId: 'TMSIC-RPI-A4E57C2CBD7D', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA138/T09-6397', logicalId: 'TMSIC-RPI-BCFF4DE35A09', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA137/T09-6422', logicalId: 'TMSIC-RPI-7C87CEA971C0', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA136/T09-6421', logicalId: 'TMSIC-RPI-A4E57C2D2005', clientId: '1021', window: 60, setup: { step: 150, refresh: 900, time: 0 } },

            {
                boxId: 'VEHÍCULO  1',
                reference: '11325',
                company: 'Adiabat',
                route: '1', driver: '1',
                phone: '000',
                sensorInfo: [
                    {
                        name: 'Área ambiente',
                        logicalId: 'TMSIC-RPI-84F3EBD20807'
                    },
                ],
                logicalId: 'HUB-AP-11325',
                clientId: '1021', window: 60,
                setup: {
                    step: 150,
                    refresh: 900,
                    time: 0
                }
            },


            //EZCART2 ECI ?

            { boxId: 'ZA01/T09-4863', logicalId: 'TMSIC-RPI-483FDAADDE4F', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA02/T09-4864', logicalId: 'TMSIC-RPI-483FDAAE00D2', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA03/T09-4865', logicalId: 'TMSIC-RPI-483FDAAD534B', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA04/T09-4866', logicalId: 'TMSIC-RPI-BCFF4DC66771', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA05/T09-4867', logicalId: 'TMSIC-RPI-483FDAAE00F3', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA08/T09-4871', logicalId: 'TMSIC-RPI-ECFABCDB20DD', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA09/T09-4872', logicalId: 'TMSIC-RPI-ECFABCDB2432', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA10/T09-4873', logicalId: 'TMSIC-RPI-BCFF4DE3B305', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA11/T09-4874', logicalId: 'TMSIC-RPI-BCFF4DE3B34F', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA13/T09-4877', logicalId: 'TMSIC-RPI-483FDAADDB76', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA15/T09-4886', logicalId: 'TMSIC-RPI-483FDAADFCA2', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA16/T09-4887', logicalId: 'TMSIC-RPI-BCFF4DE38BB6', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA17/T09-4888', logicalId: 'TMSIC-RPI-ECFABCDB2350', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA20/T09-4891', logicalId: 'TMSIC-RPI-483FDAADD752', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA22/T09-4893', logicalId: 'TMSIC-RPI-483FDAAD93DA', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA24/T09-4895', logicalId: 'TMSIC-RPI-BCFF4DC0B6BF', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA28/T09-4900', logicalId: 'TMSIC-RPI-483FDAAD5459', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },
            { boxId: 'ZA30/T09-4902', logicalId: 'TMSIC-RPI-483FDAAE00FA', clientId: '1022', window: 60, setup: { step: 150, refresh: 900, time: 0 } },

            //EZCART2 dingoo new client

            { boxId: 'ZA03/T09-4865', logicalId: 'TMSIC-RPI-483FDAAD534B', clientId: '1023', window: 60, setup: { step: 150, refresh: 900, time: 0 } },


        ]
        return device
            .filter(x => x.clientId === clientId)
            .sort(function (a, b) {
                if (a.boxId > b.boxId) return 1
                if (b.boxId > a.boxId) return -1
                return 0
            })
    }

}

export default AppSettings