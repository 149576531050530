import React from 'react'
import './index.css'

export default () => {

    return (
        <span>
            <small>version {process.env.REACT_APP_VERSION}</small>
        </span>
    )
}

