const INITIAL_STATE = {
    clientId: '-'
}

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case 'CLIENT_SELECTED':
            return {
                ...state,
                clientId: action.clientId === undefined ? action.selected : action.clientId
            }

        default:
            return state
    }
}