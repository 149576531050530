import React from 'react';
import { Button, Nav, Navbar, NavItem } from 'reactstrap';
import ProfileMenu from '../ProfileMenu'
import './index.css'


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.handleProfileMenu = this.handleProfileMenu.bind(this);
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleProfileMenu() {
    const profileMenu = document.querySelector('#profile-menu');
    const profileMenuButton = document.querySelector('.profile-menu-button');
    profileMenu.classList.toggle('profile-menu-close');
    profileMenuButton.classList.toggle('profile-menu-button-active');
  };

  render() {

    const { handleThemeSwitchButton } = this.props
    
    return (
      <div className="header-navbar header-navbar-mobile">
        <Navbar light expand>

          <Nav navbar className="mr-2 tablet-and-more">
            <Button outline color="secondaryColor" onClick={this.handleSidebarControlButton}>
              <i className="fas fa-bars"></i>
            </Button>
          </Nav>

          <div className="custom-control custom-switch tc-switch">
            <input 
              type="checkbox" 
              className="custom-control-input" 
              id="customSwitches"
              onChange={handleThemeSwitchButton}
            />
            <label className="custom-control-label" htmlFor="customSwitches">
              <i className="fas fa-sun"></i>
              </label>
          </div>

          <div id="prompt" className="hidePrompt">
            <Button id="buttonAdd" outline>
              <i className="fas fa-plus-circle" alt="install icon" title="install on home screen"></i>
            </Button>
          </div>

        <Nav 
          className="profile-menu-wrapper">
            <NavItem>
              <ProfileMenu
                handleProfileMenu={this.handleProfileMenu}
              />
            </NavItem>
          </Nav>

        </Navbar>

        
      </div>
    );
  }
}

// Declare general function to change status prompt
const promptToggle = (element, toAdd, toRemove) => {
  element.classList.add(toAdd);
  element.classList.remove(toRemove);
};

// Declare general function to get or set status into storage
const statusPrompt = {
  get: () => {
    return localStorage.getItem('statusPrompt') || null;
  },
  set: (status) => {
    localStorage.setItem('statusPrompt', status);
    return;
  }
}

window.onload = (e) => {
  // Declare init HTML elements
  const prompt = document.querySelector('#prompt');
  const buttonAdd = document.querySelector('#buttonAdd');

  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Show prompt modal if user previously not set to dismissed or accepted
    if(!statusPrompt.get() && prompt !== null ) {
        // Change status prompt
        promptToggle(prompt, 'showPrompt', 'hidePrompt');
    }
  });
  

  // Add event click function for Add button
  if(buttonAdd) {
    buttonAdd.addEventListener('click', (e) => {
      if( prompt !== null ) {
        // Change status prompt
        promptToggle(prompt, 'hidePrompt', 'showPrompt');
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              statusPrompt.set('accepted');
              console.log('User accepted the A2HS prompt');
            } else {
              statusPrompt.set('dismissed');
              console.log('User dismissed the A2HS prompt');
            }
            deferredPrompt = null;
          });
      }
    });
  }
  
}

export default Header;
