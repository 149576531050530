import React from 'react'
import { connect } from 'react-redux'
import userManager from '../../../userManager'
import ClientSelector from '../../Controls/ClientSelector/index'
import './index.css'

const signout = (event) => {
    event.preventDefault();
    userManager.signoutRedirect();
}

const ProfileMenu = (props) =>{
    
    let { user, onSignoutHandler, handleProfileMenu } = props;
    
    return (
        <div>
            <div 
                className="profile-menu-button"
                onClick={handleProfileMenu}
                ><i className="fas fa-user-cog"></i> Menú usuario
            </div>    
            <div 
                id="profile-menu" 
                className="profile-menu-close">
                <ul 
                    className="profile-menu-user-name-container"
                    onClick={handleProfileMenu}
                    >
                    <li><i className="fas fa-user-circle"></i></li>
                    <li>{user.profile.name}</li>
                </ul>    
                <ul>
                    <li>
                        <ClientSelector />
                    </li>
                    <li>
                        <button
                            onClick={onSignoutHandler}
                            className="logout-button"
                        ><i className="fa fa-sign-out-alt"></i> Salir
                        </button>
                    </li>
                </ul>
            </div>
            

        </div>        
    );
}

const mapStateToProps = (state) => {    
    return {
      user: state.oidc.user
    };
}
  
const mapDispatchToProps = (dispatch) => {
    return {        
        onSignoutHandler: (event) => signout(event)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);