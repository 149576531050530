import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'

export const getTemperatureTable = (sensorTemperatures) => {

    const columns = [
        {
            dataField: 'date',
            text: 'Fecha',
            formatter: (value) => {
                if (value === undefined || value === null || value === '') {
                    return '-'
                }
                return (
                    <span>
                        {value.toLocaleDateString()} | {value.toLocaleTimeString()}
                    </span>
                )
            }
        },
        {
            dataField: 'value',
            text: 'Temperatura',
            formatter: (value) => {
                return value === undefined || value === null ? '-' : value.toFixed(2)
            }
        }
    ]

    return (

        <BootstrapTable
            key={sensorTemperatures.info.logicalId}
            keyField={'date'}
            striped={true}
            bordered={true}
            condensed={true}
            classes={'table table-striped small dark-table'}
            data={sensorTemperatures.sensorValues}
            columns={columns}
            pagination={paginationFactory({ sizePerPage: 10 })}
        />

    )
}


export const getLocationsTable = (truck) => {

    const columns = [
        {
            dataField: 'date',
            text: 'Fecha',
            formatter: (value) => {
                if (value === undefined || value === null || value === '') {
                    return '-'
                }
                return (
                    <span>
                        {value.toLocaleDateString()} | {value.toLocaleTimeString()}
                    </span>
                )
            }
        },
        {
            dataField: 'latitude',
            text: 'Latitud'
        },
        {
            dataField: 'longitude',
            text: 'Longitud'
        }
    ]

    return (
        <>
            {
                truck.locations.length === 0
                    ?<table className="table table-striped small dark-table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Latitud</th>
                                <th>Longitud</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan="3">
                                    No se han encontrado registro de posiciones
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="3">
                                    <span className="hidden-content">
                                        FOOTER
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    : <BootstrapTable
                        keyField={'date'}
                        striped={true}
                        bordered={true}
                        condensed={true}
                        classes={'table table-striped small dark-table table-responsive-sm'}
                        data={truck.locations}
                        columns={columns}
                        pagination={paginationFactory({ sizePerPage: 10 })}
                    />
            }
        </>
    )
}