import { 
    DEVICE_ACTIONS,
    DEFAULT_DEVICE
} 
from './constants'

import * as spinnerActions from '../Controls/Spinner/actions'

import { errorHandler } from '../ErrorPage/actions'

import { getStringDateTime, getCurrentTime } from '../datetime'


/**
 * Obtiene la acción que indica el componente está cargando información de detalle
 * @returns {Object} Referencia a la acción de carga
 */
const getLoadingAction = () => {
    return {
        type: DEVICE_ACTIONS.SET_LOADING,
        isLoading: true,
        device: {...DEFAULT_DEVICE}
    }
}

/**
 * Obtiene la acción para establecer la identidad del dispositivo que se está consultando
 * @param {String} id Identidad del dispositivo
 */
const getSetIdentityAction = (id) => {    
    return {
        type: DEVICE_ACTIONS.SET_IDENTITY,
        id: atob(id),
        isLoading: true,
        device: {...DEFAULT_DEVICE},
        startDate: getStringDateTime(),
        endDate: getStringDateTime(),
        startTime: getCurrentTime(-60),
        endTime: getCurrentTime(0)
    }
}

/**
 * Manejador del evento de búsqueda
 * @returns {Function} Referencia a la función resultado de la ejecución del manejador
 */
export const onSearchHandler = () => {
    return (dispatch, getState) => {

        dispatch(getLoadingAction())

        const state = getState().DeviceReducer,
            id = state.id,
            _devices = JSON.parse(sessionStorage.getItem('devices')),
            _device = _devices.find(x => x.logicalId === id)

        if (_device === undefined) {
            return;
        }

        const _startDateString = `${state.startDate}T${state.startTime}`.replace(/-/g, '/').replace('T', ' '),
            _endDateString = `${state.endDate}T${state.endTime}`.replace(/-/g, '/').replace('T', ' '),
            _start = new Date(_startDateString),
            _end = new Date(_endDateString)
        
        _start.setHours(_start.getHours() - _device.setup.time)
        _end.setHours(_end.getHours() - _device.setup.time)

        const start = _start.toISOString(),
            end = _end.toISOString(),
            url = `${process.env.REACT_APP_DATA_API_URL}?ids=${id}&start=${start}&end=${end}`;

        // start spinner
        dispatch(spinnerActions.getShowSpinner())
        
        fetch(url)
            .then((httpResponse) => httpResponse.json())
            .then((response) => {
                let devices = response.devices
                if (devices.length === 0) {
                    devices = []
                    devices[0] = { values: [] }
                }

                devices[0].values = devices[0].values.filter(
                    x => !isNaN(x.payload.Temperature)
                        && x.payload.Temperature > -100)
                        // && x.payload.Temperature < 127)
                

                const action = {
                    type: DEVICE_ACTIONS.SET_LOADED,
                    isLoading: false,
                    device: {
                        ..._device,
                        values: [...devices[0].values]
                    }
                }

                const report = {
                    device: action.device,
                    startDate: state.startDate,
                    endDate: state.endDate,
                    startTime: state.startTime,
                    endTime: state.endTime
                }

                sessionStorage.setItem('report', JSON.stringify(report))

                dispatch(action)
                // finish spinner
                dispatch(spinnerActions.getHideSpinner())
            })
            .catch((error) => {
                // finish spinner
                dispatch(spinnerActions.getHideSpinner())
               dispatch(errorHandler(error))
            });
    }
}

/**
 * Manejador del evento de inicialización de la vista de detalle de un dispositivo
 * @param {String} id Identidad del dispositvo a consultar
 * @returns {Function} Referencia al manejador del evento de inicialización
 */
export const onInitializeHandler = (id) => {
    return (dispatch) => {

        dispatch(getSetIdentityAction(id))

        dispatch(onSearchHandler())
    }
}

/**
 * Obtiene la acción de modificación de criterio de búsqueda
 * @param {string} value Valor asignado al criterio de filtrado que ha cambiado
 * @param {string} source Identificador del criteiro de filtrado que ha sido modificado
 * @return {Object} Referencia a la acción de cambio de criterio
 */
export const getChangeCriteriaAction = (value, source) => {

    let actionType = DEVICE_ACTIONS.SET_START_DATE

    switch (source) {
        case 'startDate':
            actionType = DEVICE_ACTIONS.SET_START_DATE;
            break
        case 'startTime':
            actionType = DEVICE_ACTIONS.SET_START_TIME;
            break
        case 'endDate':
            actionType = DEVICE_ACTIONS.SET_END_DATE;
            break
        case 'endTime':
            actionType = DEVICE_ACTIONS.SET_END_TIME;
            break
        default:
            actionType = DEVICE_ACTIONS.SET_START_DATE
            break;
    }

    return {
        type: actionType,
        value: value,
        isLoading: false
    }
}