import React from 'react';
import AppVersion from '../../Controls/AppVersion'

export default () => {
  return (
    <footer className="tc-footer">
      <span>
      <small>TRACK AND CHAIN S. L.</small>
      </span>
      <AppVersion />
      <span className="mobile"><small>App compatible con Chrome, Firefox, Safari, Samsung Internet y Opera</small></span>
      <span className="only-tablet"><small>App compatible con Chrome, Firefox, Edge</small></span>
      <span className="laptop"><small>App compatible con Chrome, Firefox, Safari y Opera</small></span>
    </footer>    
  );
};
