import { ERRORS_ACTIONS, URLS } from './constants'
import { push } from 'connected-react-router';

const getErrorAction = (error) => {
    return {
        type: ERRORS_ACTIONS.SET_ERROR,
        title: error.title,
        subtitle: error.subtitle,
        description: error.description,
        details: error.details
    }
}

export const errorHandler = (error) => {
    return (dispatch) => {
        dispatch(getErrorAction(error));
        dispatch(push(URLS.errorPage))
    }
}