import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as oidcReducer } from 'redux-oidc'

import SideBarReducer from '../components/Layout/SideBar/reducer'
import SpinnerReducer from '../components/Controls/Spinner/reducer'
import ToastReducer from '../components/Controls/Toast/reducer'
import ErrorReducer from '../components/ErrorPage/reducer'
import HomeReducer from '../components/HomePage/reducer'
import DevicesReducer from '../components/DevicesPage/reducer'
import DeviceReducer from '../components/DevicePage/reducer'
import TrucksReducer from '../components/TrucksPage/reducer'
import TruckReducer from '../components/TruckPage/reducer'

export default (history) => combineReducers({
    router: connectRouter(history),
    oidc: oidcReducer,
    SideBarReducer,
    SpinnerReducer,
    ToastReducer,    
    ErrorReducer,    
    HomeReducer,
    DeviceReducer,
    DevicesReducer,
    TrucksReducer,
    TruckReducer
})