import React from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'
import { Link } from 'react-router-dom'
import MarkersMap from '../Controls/MarkersMap'

const Content = (props) => {

    const {
        trucks,
        center,
        zoom,
        setZoom
    } = props

    return (
        <div className="container-fluid page-content-wrapper">
            <Card className="black-card">
                <CardHeader>
                    SEGUIMIENTO DE TRANSPORTES
                </CardHeader>
                <CardBody>
                    <MarkersMap
                        markers={trucks}
                        center={center}
                        zoom={zoom}
                        onClick={setZoom}
                    />
                </CardBody>
                <CardFooter>
                    <div className="hidden-content">
                        FOOTER
                    </div>
                </CardFooter>
            </Card>

            <div className="whitespace"></div>

            <div className="responsive-page-table-card">
                <Card className="black-card">
                    <CardHeader>
                        VEHÍCULOS ACTIVOS
                    </CardHeader>
                    <CardBody className="responsive-page-table-container">
                        <table className="responsive-page-table table table-striped small dark-table">
                            <thead>
                                <tr>
                                    <th>Matrícula</th>
                                    <th>Expedición</th>
                                    <th>Teléfono</th>
                                    <th>Fecha</th>
                                    <th>Detalles</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trucks !== undefined && trucks.length > 0
                                    ? trucks.map(x => {
                                            const date = new Date(x.date)
                                            return (<tr key={x.reference}>
                                                <td data-th="Matrícula">{x.reference}</td>
                                                <td data-th="Expedición">{x.route}</td>
                                                <td data-th="Teléfono">{x.phone}</td>
                                                <td data-th="Fecha">
                                                    {date.toLocaleDateString()} | {date.toLocaleTimeString()}
                                                </td>
                                                <td data-th="Detalles">
                                                    <Link to={`/trucks/details/${btoa(x.reference)}`}>
                                                        Ver detalles
                                                    </Link>
                                                </td>
                                            </tr>)
                                        })
                                    : <tr>
                                        <td data-th="Vehículos" colSpan="5">
                                            No hay vehículos activos
                                        </td>
                                    </tr>
                                }
                            </tbody>
                            {/* <tfoot>
                                <tr>
                                    <td colSpan="5">
                                        <span className="hidden-content">
                                            FOOTER
                                        </span>
                                    </td>
                                </tr>
                            </tfoot> */}
                        </table>
                    </CardBody>
                    {/* <CardFooter>
                        <div className="hidden-content">
                            FOOTER
                        </div>
                    </CardFooter> */}
                </Card>
            </div>

        </div>
    )
}

Content.propTypes = {
    trucks: PropTypes.array.isRequired,
    center: PropTypes.array.isRequired,
    zoom: PropTypes.number,
    setZoom: PropTypes.func.isRequired
}

Content.defaultValues = {
    trucks: [],
    center: []
}

export default Content