import { TRUCK_ACTIONS } from './constants'
import * as spinnerActions from '../Controls/Spinner/actions'
import { getStringDateTime, getCurrentTime } from '../datetime'

/**
 * Obtiene la acción que indica que el componente está cargando información de detalle
 * @returns {Object} Referencia a la acción de carga
 */
const getLoadingAction = () => {
    return {
        type: TRUCK_ACTIONS.SET_LOADING,
        isLoading: true,
        truck: {}
    }
}

/**
 * Obtiene la acción para establecer la identidad del vehículo que se está consultando
 * @param {String} id Identidad del vehículo
 */
const getSetIdentityAction = (id) => {
    const reference = atob(id)
    return {
        type: TRUCK_ACTIONS.SET_IDENTITY,
        reference: reference,
        isLoading: true,
        truck: {},
        startDate: getStringDateTime(),
        endDate: getStringDateTime(),
        startTime: getCurrentTime(-60),
        endTime: getCurrentTime(0)
    }
}

/**
 * Función de ordenación de los registros de posición de un vehículo
 * @param {Object} a Referencia a la fecha a comparar (previa)
 * @param {Object} b Referencia a la fecha a comparar (posterior)
 * @returns {Number} Retorna 1 si a > b, 0 si son iguales, -1 en cualquier caso
 */
const orderByDate = (a, b) => {
    if (a.date > b.date)
        return -1
    if (b.date > a.date)
        return 1
    return 0
}

/**
 * Filtro de comprobación para validar si la métrica pasada como argumento dispone de registros de temperatura
 * @param {Object} x Referencia a la métrica
 * @returns {Boolean} Resultado de la validación. Verdadero si dispone de registros.
 */
const filterTemperatures = (x) => {
    return x.payload.Childs !== undefined
        && x.payload.Childs !== null
        && x.payload.Childs.length > 0
}

/**
 * Filtro de comprobación para validar si la métrica pasada como argumento están en el rango de tiempo (fechas) definido
 * @param {Object} x Referencia a la métrica
 * @returns {Boolean} Resultado de la validación.
 */
const filterTemperatureByRange = (x, start, end) => {
    const date = new Date(x.Payload.Date)
    return date >= start && date <= end
}

/**
 * Filtro de comprobación para validar si el registro de posición pasado como argumento está en el rango de tiempo (fechas) definido
 * @param {Object} x Referencia al registro de posición
 * @returns {Boolean} Resultado de la validación.
 */
const filterLocationByRange = (x, start, end) => {
    const date = new Date(x.payload.Location.CreatedDate)

    return date >= start && date <= end
}

/**
 * Filtro de comprobación para eliminar los registros repetidos de la colección
 * @param {Object} x Referencia al registro de posición
 * @returns {Boolean} Resultado de la validación.
 */
const filterDuplicated = (value, index, self) => {

    const item = self.find(x => x.date.getTime() === value.date.getTime())

    return self.indexOf(item) === index
}

/**
 * Transformador de registros de temperatura al formato compatible con controles de Temperatura
 * @param {Object} x Registro de temperatura
 * @returns {Object} DTO control de temperatura
 */
const mapTemperature = (x) => {
    const _date = new Date(x.Payload.Date)    

    return {
        value: x.Payload.Temperature,
        date: _date
    }
}

/**
 * Transformador de registros de posición al formato compatible con controles de localización
 * @param {Object} x Registro de localización
 * @returns {Object} DTO control de localización
 */
const mapLocations = (x) => {
    return {
        latitude: x.payload.Location.Latitude,
        longitude: x.payload.Location.Longitude,
        date: new Date(x.payload.Location.CreatedDate)
    }
}

/**
 * Transformador de registros de posición al formato compatible con GoogleMap
 * @param {Object} x Registro de localización
 * @returns {Object} DTO control GoogleMap
 */
const mapPath = (x) => {
    return {
        ...x,
        'lat': x.latitude,
        'lng': x.longitude
    }
}

/**
 * Obtiene la información de posiciones y temperatura de vehículo desde la información de la API
 * @param {Array} values Referencia a la colección de métricas del vehículo
 * @param {Date} start Referencia a la fecha de inicio del filtro
 * @param {Date} end Referencia a la fecha de fin del filtro
 * @param {String} sensor Identificador del sensor asociado al dispositivo
 * @returns {Object} Referencia al contenedor de métricas
 */
const getTruckInfo = (values, start, end, sensorInfo) => {

    let _tempValues = [],
        sensorIndex = 0,
        _locationValues = []

    values.filter(filterTemperatures)

    _locationValues = values

    for ( const sensor of sensorInfo ) {

        let sensorObject = {
            info: sensor,
            sensorValues: []
        }

        values
            .map(x => {
                const childs = x.payload.Childs.filter(x => x.LogicalId === sensor.logicalId);
                sensorObject = {
                    info: sensor,
                    sensorValues: sensorObject.sensorValues.concat(childs)
                }                
                return x
            })
        
        _tempValues[sensorIndex] = sensorObject

        sensorIndex ++  
    }

    const temps = _tempValues.map( sensorObject => {
                                sensorObject = {
                                    info: sensorObject.info,
                                    sensorValues: sensorObject.sensorValues
                                                                        .filter( x => filterTemperatureByRange( x, start, end))
                                                                        .map( x => mapTemperature(x))
                                                                        .sort(orderByDate)
                                                                        .filter(filterDuplicated)
                                }                          
                    return sensorObject
                    })


    const locations = _locationValues
                                .filter(x => filterLocationByRange(x, start, end))
                                .map(mapLocations)
                                .sort(orderByDate)
                                .filter(filterDuplicated),
                                path = locations.map(mapPath)


    return {
        locations: locations,
        temperatures: temps,
        path: path
    }
}

/**
 * Obtiene la información del dispositivo a partir de la información de la petición http
 * @param {Object} response Referencia a la respuesta de la petición
 * @returns {Object} Referencia al dispositivo
 */
const getDevice = (response) => {
    let devices = response.devices
    if (devices.length === 0) {
        devices = []
        devices[0] = { values: [] }
    }
    return devices[0]
}

/**
 * Manejador del evento de búsqueda
 * @returns {Function} Referencia a la función resultado de la ejecución del manejador
 */
export const onSearchHandler = () => {
    return (dispatch, getState) => {

        dispatch(getLoadingAction())

        const state = getState().TruckReducer,
            reference = state.reference,            
            devices = JSON.parse(sessionStorage.getItem('devices')),
            _truck = devices.find(x => x.reference === reference)
        
        if (_truck === undefined) {
            dispatch({
                type: TRUCK_ACTIONS.SET_LOADED,
                isLoading: false,
                truck: {
                    locations: [],
                    path: [],
                    temperatures: []
                }
            })
            return
        }

        const _startDateString = `${state.startDate}T${state.startTime}`.replace(/-/g, '/').replace('T', ' '),
        _endDateString = `${state.endDate}T${state.endTime}`.replace(/-/g, '/').replace('T', ' '),
        _start = new Date(_startDateString),
        _end = new Date(_endDateString),
            id = _truck.logicalId,
            start = _start.toISOString(),
            end = _end.toISOString()
            let url = ""

            if ( id.includes('TMSIC-HUBMOCK-') ) {
                url = '/data/mock-truck.json'
            } 
            
            if ( id.includes('TMSIC-HUB-') ){
                url = `${process.env.REACT_APP_DATA_API_URL}?ids=${id}&start=${start}&end=${end}`
            }
            
        // start spinner
        dispatch(spinnerActions.getShowSpinner())

        fetch(url)
            .then((httpResponse) => httpResponse.json())
            .then((response) => {
                const devices = getDevice(response),
                    truckInfo = getTruckInfo(devices.values, _start, _end, _truck.sensorInfo),
                    action = {
                        type: TRUCK_ACTIONS.SET_LOADED,
                        isLoading: false,
                        truck: {
                            ..._truck,
                            ...truckInfo
                        }
                    }
                dispatch(action)
                // finish spinner
                dispatch(spinnerActions.getHideSpinner())
            })
            .catch((error) => {
                console.log(error)
            })
    }
}

/**
 * Manejador del evento de inicialización de la vista de detalle de un vehículo
 * @param {String} id Identidad del vehículo a consultar
 * @returns {Function} Referencia al manejador del evento de inicialización
 */
export const onInitializeHandler = (id) => {
    return (dispatch) => {

        dispatch(getSetIdentityAction(id))

        dispatch(onSearchHandler())
    }
}

/**
 * Obtiene la acción de modificación de criterio de búsqueda
 * @param {string} value Valor asignado al criterio de filtrado que ha cambiado
 * @param {string} source Identificador del criteiro de filtrado que ha sido modificado
 * @return {Object} Referencia a la acción de cambio de criterio
 */
export const getChangeCriteriaAction = (value, source) => {

    let actionType = TRUCK_ACTIONS.SET_START_DATE

    switch (source) {
        case 'startDate':
            actionType = TRUCK_ACTIONS.SET_START_DATE;
            break
        case 'startTime':
            actionType = TRUCK_ACTIONS.SET_START_TIME;
            break
        case 'endDate':
            actionType = TRUCK_ACTIONS.SET_END_DATE;
            break
        case 'endTime':
            actionType = TRUCK_ACTIONS.SET_END_TIME;
            break
        default:
            actionType = TRUCK_ACTIONS.SET_START_DATE
            break;
    }

    return {
        type: actionType,
        value: value,
        isLoading: false
    }
}