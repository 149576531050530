import React from 'react'
import { connect } from "react-redux";
import userManager from '../../userManager';
import './index.css'

const signin = (event) => {    
    event.preventDefault()
    userManager.signinRedirect()
}

const LoginPage = ({onSigninHandler}) => {

    return (
        <div className="login-page-container login-page-container-dark">
            <div className="login-card">
                <p className="login-p-main">
                BANCO DE PRUEBAS IOT DASHBOARD
                </p>
                <p className="login-p-second ">
                    Pulse el botón para identificarse y entrar
                </p>
                <button 
                    className="btn login-button" 
                    type="button" 
                    onClick={onSigninHandler}
                >
                    <img
                    src="/assets/images/icons/tc-pwa-icon-5-64.png"
                    width="40"
                    height="30"
                    className="login-page-button-icon"
                    alt="app icon"
                    />
                </button>
            </div>        
            
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSigninHandler: (event) => signin(event)
    }
}

export default connect(null, mapDispatchToProps)(LoginPage)