import { SPINNER_ACTIONS } from './constant'

const INITIAL_STATE = {
    show: false
}

export default (state =  INITIAL_STATE, action) => {
    
    switch(action.type){

        case SPINNER_ACTIONS.SHOW_SPINNER:
            return {
                show: true
            }
        case SPINNER_ACTIONS.HIDE_SPINNER:
            return {
                show: false
            }
            
        default:
            return state;
    }
}