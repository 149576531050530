import { ERRORS_ACTIONS } from './constants';

const INITIAL_STATE = {
    title: '',
    subtitle: '',
    description: '',
    details: ''
}

export default (state =  INITIAL_STATE, action) => {

    switch(action.type){

        case ERRORS_ACTIONS.SET_ERROR:
            return {
                title: action.title,
                subtitle: action.subtitle,
                description: action.description,
                details: action.details
            }

        default:
            return state;
    }

}