import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Content from './content'
import * as actions from './actions'
import './index.css'

const MILI_SECONDS_PER_MINUTE = 30 * 1000

class TrucksPage extends Component {

    componentDidMount() {

        const {
            setLoading,
            getTrucks,
            reloadTrucks
        } = this.props        

        setLoading()
        getTrucks()

        this.autoRefresh = setInterval(() => {
            reloadTrucks()
        }, MILI_SECONDS_PER_MINUTE)
    }

    componentWillUnmount() {
        if (this.autoRefresh !== undefined && this.autoRefresh !== null) {
            clearInterval(this.autoRefresh)
        }
    }

    render() {

        const {
            trucks,
            zoom,
            center,
            isLoading,
            setZoom
        } = this.props

        if (isLoading)
            return ''

        return (
            <Content
                trucks={trucks}
                center={center}
                zoom={zoom}
                setZoom={setZoom}
            />
        )
    }
}

TrucksPage.propTypes = {
    trucks: PropTypes.array.isRequired,
    center: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getTrucks: PropTypes.func.isRequired,
    reloadTrucks: PropTypes.func.isRequired,
    setZoom: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired
}

TrucksPage.defaultProps = {
    trucks: [],
    isLoading: false
}

const mapStateToProps = (state) => {
    const current = state.TrucksReducer
    return {
        isLoading: current.isLoading,
        trucks: current.trucks,
        center: current.center,
        zoom: current.zoom
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTrucks: () => dispatch(actions.getTrucks()),
        reloadTrucks: () => dispatch(actions.reloadTrucks()),
        setLoading: () => dispatch(actions.setLoading()),
        setZoom: (truck)  => dispatch(actions.setZoom(truck))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrucksPage)