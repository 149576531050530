import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Content from './content'
import * as actions from './actions'
import './index.css'

class DevicesPage extends Component {   

    componentDidMount() {

        const {
            setLoading,
            getDevices
        } = this.props

        setLoading()
        getDevices()
     }

    componentWillUnmount() { }

    render(){

        const {
            devices,
            isLoading
        } = this.props


        if(isLoading){
             return (
                <div className="container-fluid page-content-wrapper">
                    <div className="loading-feedback">
                        <p>Cargando datos de dispositivos ...</p>
                    </div>
                </div>
             )
        }

        return (            
            <Content 
                devices={devices} 
                darktheme={this.props.darktheme}
                />
        )
    }
}

DevicesPage.propTypes = {    
    devices: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired
}

DevicesPage.defaultProps = {
    devices: [],
    isLoading: false
}

const mapStateToProps = (state) => {
    const current = state.DevicesReducer    
    return {        
        isLoading: current.isLoading,
        devices: current.devices
    }
}

const mapDispatchToProps = dispatch =>{  
    return { 
        getDevices: () => dispatch(actions.getDevices()),
        setLoading: () => dispatch(actions.setLoading())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesPage)