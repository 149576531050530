import React from 'react'
import PropTypes from 'prop-types'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import './index.css'

const DeviceDetails = (props) => {

    const { device } = props

    return (
        <div className="device-details col-md-12 col-lg-7 col-xl-6">
            <Card className="black-card">
                <CardHeader>
                    DETALLES DEL CONTENEDOR
                </CardHeader>
                <CardBody className="responsive-page-table-container">
                    <table className="responsive-page-table table table-striped small dark-table">
                        <thead>
                            <tr>
                                <th>Contenedor</th>
                                <th>Dispositivo</th>
                                <th>T. Muestreo</th>
                                <th>T. Actualización</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td data-th="Contenedor">{device.boxId}</td>
                                <td data-th="Dispositivo">{device.logicalId}</td>
                                <td data-th="T. Muestreo">{device.setup.step/60} min</td>
                                <td data-th="T. Actualización">{device.setup.refresh/60} min</td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
                <CardFooter>
                    <span className="hidden-content">
                        FOOTER
                    </span>
                </CardFooter>
            </Card>
        </div>
    )
}

DeviceDetails.propTypes = {
    device: PropTypes.object.isRequired
}

export default DeviceDetails