import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import './index.css'

class AppBreadcrumb extends Component {

    componentDidMount() { }

    componentWillUnmount() { }

    getNodeName(name, path) { 

        name = name.toUpperCase()

        let node = {
            text: '',
            to: '', 
            active: false
        }

        switch (name) {
            case 'TRUCKS':
                node.text = 'VEHÍCULOS'
                node.to = '/trucks'
                break
            case 'CONTAINERS':
                node.text = 'CONTENEDORES'
                node.to = '/containers'
                break
            case 'ERROR':
                node.text = 'ERROR'
                node.to = '/error'
                break
            case 'DETAILS':                
                node.text = 'DETALLES'
                node.to = `/${path[0]}/details/${path[2]}`
                break
            default:
                node.text = 'INICIO'
                node.to = '/'
                break
        }

        return node
    }

    getCurrentPath() {

        const path = this.props.path

        let nodes = []

        nodes.push({
            text: 'INICIO',
            to: '/',
            active: false
        })        

        nodes = nodes.concat(path.slice(0,2).map(x => this.getNodeName(x, path)))

        return nodes.map((x, i) => {
            return (
                <BreadcrumbItem className="app-breadcrumb-item" key={i}>
                    <Link to={x.to}>{x.text}</Link>
                </BreadcrumbItem>
            )
        })
    }

    render() {        

        const nodes = this.getCurrentPath()

        return (
            <div>
                <div className="container-fluid app-breadcrumb-container">
                    <Breadcrumb className="app-breadcrumb">
                        {nodes}
                    </Breadcrumb>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    let path = ''

    try {
        path = state.router.location.pathname.split('/').filter(x => x !== '')
    }
    catch{
        path = '/'
    }

    return {
        path: path
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBreadcrumb)