import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import configureStore, { history } from './store/store'
import userManager from './userManager';
import App from './App';
import * as serviceWorker from './serviceWorker'

const store = configureStore();

ReactDOM.render(       
    <Provider store={store}>
        <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter >
        </OidcProvider>
    </Provider>
    , document.getElementById('root')
);

serviceWorker.register();
