import React from 'react'
import ProptTypes from 'prop-types'
import { connect } from 'react-redux'
import './index.css'
//import * as actions from './actions';

const ErrorPage = (props) => {

  const {
      title,
      subtitle,
      description,
      details,
      errorBoundaryTitle
  } = props;

  return (
    <div className="container-fluid page-content-wrapper error-card-container">
        <div className="card black-card error-card">
            <div className="card-body error-body">
                
                <div>
                    <div className="preloader">
                        <div className="preloader__square">
                        <img
                            src="/assets/images/icons/tc-pwa-icon-5-64.png"
                            className="tc-icon-square"
                            alt="Track and Chain icon"
                        />                        </div>
                        <div className="preloader__square">
                        </div>
                        <div className="preloader__square">
                        </div>
                        <div className="preloader__square">
                        </div>
                    </div>
                </div>
                <div>
                    {errorBoundaryTitle
                    ? <h1 className="card-title">
                        {errorBoundaryTitle}
                    </h1>
                    : <h1 className="card-title">
                        {title}
                    </h1>
                    }

                    <p className="card-title">
                        Lo sentimos! Espere unos minutos y si el error persiste, contacte con
                        <a href="mailto:soporte@trackandchain.com"> soporte técnico</a>
                    </p>
                    {subtitle
                        ? <p className="card-text">
                            {subtitle}
                        </p>
                        : ''
                    }
                    {description
                        ? <p className="card-text">
                            {description}
                        </p>
                        : ''
                    }
                    {details
                        ? <p className="card-text small">
                            {details}
                        </p>
                        : ''
                    }
                    
                </div>
                
            </div>
        </div>
    </div>
  );
}

ErrorPage.propTypes = {
    title: ProptTypes.string.isRequired,
    subtitle: ProptTypes.string,
    description: ProptTypes.string,
    details: ProptTypes.string
}

ErrorPage.defaultValues = {
    title: '',
    subtitle: '',
    description: '',
    details: ''
}

const mapStateToProps = (state) => {

    const current = state.ErrorReducer;

    return {
        title: current.title,
        subtitle: current.subtitle,
        description: current.description,
        details: current.details
    }
}

const mapDispatchToProps = dispatch =>{  
  return {
    
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);