import { Component } from 'react'
import { connect } from 'react-redux'
import fetchIntercept from 'fetch-intercept'
// https://github.com/werk85/fetch-intercept
// https://www.npmjs.com/package/fetch-intercept
// https://stackoverflow.com/questions/53507153/authentication-bearer-in-react

class Interceptor extends Component {

    state = {
        unregister: null
    }

    componentDidMount() {

        const { user } = this.props       

        if (!user || user.expired || this.state.unregister !== null) {
            return
        }

        const unregister = fetchIntercept.register({

            request: function (url, config) {

                const id_token = user.id_token

                if (id_token === undefined || id_token === null)
                    return [url, config]

                const _config = (config === undefined || config === null)
                    ? {
                        headers: {
                            'Authorization': `Bearer ${id_token}`
                        }
                    }
                    : {
                        ...config,
                        headers: {
                            ...config.headers,
                            'Authorization': `Bearer ${id_token}`
                        }
                    }

                return [
                    url,
                    _config
                ]
            }
        })

        this.setState({
            unregister: unregister
        })
    }

    componentWillMount() {
        if (this.state.unregister !== null) {
            this.state.unregister()
        }
    }

    render() {
        return ''
    }

}


const mapStateToProps = (state) => {

    const oidc = state.oidc

    return {
        user: oidc.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Interceptor)