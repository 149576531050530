import React from 'react'
import { Switch, Route } from 'react-router'

// pages
import ErrorPage from '../ErrorPage'
import HomePage from '../HomePage'
import DevicePage from '../DevicePage'
import DevicesPage from '../DevicesPage'
import ReportPage from '../ReportPage'
import TrucksPage from '../TrucksPage'
import TruckPage from '../TruckPage'
import GatewayPage from '../GatewayPage'

const AppRouter = ({darktheme}) => {
    return (
        <Switch>          
          <Route 
            path='/containers/details/:id'
              render={(props) =>
              <DevicePage
                darktheme={darktheme}
                match={props.match}
              />} />
          <Route
            exact
            path='/containers'
            render={(props) =>
              <DevicesPage
                darktheme={darktheme}
                match={props.match}
              />} />
          <Route
            exact
            path='/containers/report'
            component={ReportPage}
          />
          <Route
            exact
            path='/gateway'
            component={GatewayPage}
          />
          <Route
            exact
            path='/trucks'
            component={TrucksPage}
          />
          <Route
            exact
            path='/trucks/details/:reference'
            render={(props) =>
              <TruckPage
                darktheme={darktheme}
                match={props.match}
              />} />
          <Route 
            path='/error'
            component={ErrorPage} 
          />
          <Route 
            path='/'
            render={() =>
              <HomePage
                darktheme={darktheme}
              />} />
        </Switch>
    )
}

export default AppRouter;

