import React from 'react'
import { TempControl } from '../index'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import './index.css'

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import './index.css'

const TmsicRpi = (props) => {

    const {
        device,
        darktheme
    } = props

    const orderByDate = (a, b) => {
        if (a.date > b.date)
            return 1
        if (b.date > a.date)
            return -1
        return 0
    }


    const completeAndCleanData = (device, items) => {
        const step = device.setup.step * 1000

        let _items = [items[0]]

        for (let i = 1; i < items.length; i++) {

            const delta = items[i].time - _items[_items.length - 1].time

            if (delta >= step) {
                const n = Math.floor(delta / step).toFixed(0),
                    _time = items[i - 1].time,
                    _base = items[i - 1].value,
                    _inc = (items[i].value - items[i - 1].value) / n
                for (let j = 1; j <= n; j++) {
                    _items.push({
                        time: _time + (j * step),
                        date: new Date(_time + (j * step)),
                        value: (parseFloat(_base) + (j * _inc)).toFixed(2),
                        estimated: true
                    })
                }
            }
            else if (delta < step || delta === 0) {
                //console.log(items[i])
            }
            else {
                _items.push({
                    ...items[i],
                    estimated: false
                })
            }
        }
        return _items
    }

    const getItems = (device) => {
        const items = device.values.map(x => {
            let date = new Date(x.date.substring(0, 17) + '00.000Z')
            date.setHours(date.getHours() + device.setup.time)
            return {
                time: date.getTime(),
                date: date,
                value: x.payload.Temperature.toFixed(2)
            }
        }).sort(orderByDate)

        return items.length === 0
            ? []
            : completeAndCleanData(device, items)
    }

    const items = getItems(device)

    let columns = [
        {
            dataField: 'date',
            text: 'Hora',
            formatter: (value) => {
                if (value === undefined || value === null || value === '') {
                    return '-'
                }
                return (
                    <span>
                        {value.toLocaleDateString()} | {value.toLocaleTimeString()}
                    </span>
                )
            }
        },
        {
            dataField: 'value',
            text: 'Medidas'
        }
    ]

    const metrics = [...items]

    return (
        <div className="row">
            <div className="col-md-12 col-lg-6 devices-card-wrapper">
                <Card className="black-card">
                    <CardHeader>
                        Registro
                    </CardHeader>
                    <CardBody>
                        <TempControl 
                            items={items}
                            darktheme={darktheme}
                            ></TempControl>
                    </CardBody>
                    <CardFooter>
                        <span className="hidden-content">
                            Footer
                        </span>
                    </CardFooter>
                </Card>
                <div className="whitespace"></div>
            </div>
            <div className="col-md-12 col-lg-6 devices-card-wrapper">
                <Card className="black-card">
                    <CardHeader>
                        Medidas
                    </CardHeader>
                    <CardBody>
                        <BootstrapTable
                            keyField={'time'}
                            striped={true}
                            bordered={true}
                            condensed={true}
                            classes={'small dark-table'}
                            data={metrics.reverse()}
                            columns={columns}
                            pagination={paginationFactory({ sizePerPage: 10 })}
                        />
                    </CardBody>
                    <CardFooter>
                        <span className="hidden-content">
                            Footer
                        </span>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}

export default TmsicRpi


