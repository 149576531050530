import React from 'react'
import PropTypes from 'prop-types'
import './index.css'

import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from 'reactstrap'

import {
    FaSearch,
    FaFilePdf
} from 'react-icons/fa'

import ExcelReport from '../ExcelReport'

const DetailsFilter = (props) => {

    const {
        startDate,
        startTime,
        endDate,
        endTime,
        onChange,
        onSearch,
        exportPdf,
        isLoading,
        exports
    } = props

    const getExport = exports => {

        if(!exports)
            return ''

        return <>
            <button
                onClick={exportPdf}
                className="btn btn-info btn-print">
                <FaFilePdf />
            </button>
            <ExcelReport />
        </>
    }

    return (
        <div className="details-filter col-md-12 col-lg-5 col-xl-6">
            <Card className="black-card">
                <CardHeader>
                    FILTRO DE BÚSQUEDA
                </CardHeader>
                <CardBody>
                    <div>
                        <div className="form-row">
                            <div className="form-group col-md-7">
                                <label>
                                    Fecha y hora de inicio:
                                </label>
                                <input
                                    type="date"
                                    defaultValue={startDate}
                                    onChange={(event) => onChange(event.target.value, 'startDate')}
                                    className="form-control form-control-sm details-filter-input-date"
                                />
                            </div>
                            <div className="form-group col-md-5">
                                <label className="hidden-content">
                                    Hora
                                </label>
                                <input
                                    type="time"
                                    defaultValue={startTime}
                                    onChange={(event) => onChange(event.target.value, 'startTime')}
                                    className="form-control form-control-sm details-filter-input-time"
                                />
                            </div>
                            <div className="form-group col-md-7">
                                <label>
                                    Fecha y hora de fin:
                                </label>
                                <input
                                    type="date"
                                    defaultValue={endDate}
                                    onChange={(event) => onChange(event.target.value, 'endDate')}
                                    className="form-control form-control-sm details-filter-input-date"
                                />
                            </div>
                            <div className="form-group col-md-5">
                                <label className="hidden-content">
                                    Hora:
                                </label>
                                <input
                                    type="time"
                                    defaultValue={endTime}
                                    onChange={(event) => onChange(event.target.value, 'endTime')}
                                    className="form-control form-control-sm details-filter-input-time"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="float-right">
                        <button
                            onClick={onSearch}
                            className="btn btn-info"
                        >
                            <FaSearch />
                        </button>
                        {
                            isLoading ? '' : getExport(exports)
                        }
                    </div>
                </CardBody>
                <CardFooter>
                    <span className="hidden-content">
                        Footer
                    </span>
                </CardFooter>
            </Card>
        </div>
        
    )
}

DetailsFilter.propTypes = {
    startDate: PropTypes.string.isRequired,
    startTime: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    exportPdf: PropTypes.func.isRequired,
    exportExcel: PropTypes.func.isRequired,
    exports: PropTypes.bool
}

DetailsFilter.defaultProps = {
    exports: true
}

export default DetailsFilter