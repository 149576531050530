import { getStringDateTime, getCurrentTime } from '../../datetime'

export const orderByDate = (a, b) => {
    if (a.date > b.date)
        return 1
    if (b.date > a.date)
        return -1
    return 0
}

export const getStart = (minutesToSubtract) => {
    const _startDateString = `${getStringDateTime()}T${getCurrentTime(-minutesToSubtract)}`.replace(/-/g, '/').replace('T', ' '),
    _start = new Date(_startDateString),
    _devices = JSON.parse(sessionStorage.getItem('devices')),
    _device = _devices[0]
    _start.setHours(_start.getHours() - (_device.setup.time))

    const start = _start.toISOString()

    return start
}
