import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import './index.css'
import { detectSafariBrowser } from '../transversal'

class ReportPage extends Component {

    afterPrintHandler(event) {
        if(detectSafariBrowser() === true) {
            return window.alert('Cierre esta ventana para volver al detalle del dispositivo')
            //return window.addEventListener("load", window.close())
            // return setTimeout(() => window.close(), 2000)
            // return window.addEventListener("load", window.close)
        } else  {
            return window.close()
        }
    }

    constructor() {

        super()

        const json = sessionStorage.getItem('report'),
            _report = JSON.parse(json)

        const orderByDate = (a, b) => {
            if (a.date > b.date)
                return 1
            if (b.date > a.date)
                return -1
            return 0
        }

        const completeAndCleanData = (device, items) => {
            const step = device.setup.step * 1000
            let _items = [items[0]],
                _estimated = []
            for (let i = 1; i < items.length; i++) {
                const delta = items[i].time - _items[_items.length - 1].time
                if (delta > step) {
                    const n = Math.floor(delta / step).toFixed(0),
                        _time = items[i - 1].time,
                        _base = items[i - 1].value,
                        _inc = (items[i].value - items[i - 1].value) / n
                    for (let j = 1; j <= n; j++) {
                        _items.push({
                            time: _time + (j * step),
                            date: new Date(_time + (j * step)),
                            value: null,//(parseFloat(_base) + (j * _inc)).toFixed(2),
                            estimated: true
                        })

                        _estimated.push({
                            time: _time + (j * step),
                            date: new Date(_time + (j * step)),
                            value: (parseFloat(_base) + (j * _inc)).toFixed(2),
                            estimated: true
                        })
                    }
                }
                else if (delta === 0) {
                    //console.log(items[i])
                }
                else if (delta < step) {
                    //console.log(items[i])
                }
                else {

                    _estimated.push({
                        time: items[i].time,
                        date: items[i].date,
                        value: null,
                        estimated: false
                    })

                    _items.push({
                        ...items[i],
                        estimated: false
                    })
                }
            }

            return [_items, _estimated]
        }

        let values = _report.device.values.map(x => {
            let date = new Date(x.date.substring(0, 17) + '00.000Z')
            date.setHours(date.getHours() + _report.device.setup.time)
            return {
                time: date.getTime(),
                date: date,
                value: x.payload.Temperature.toFixed(2)
            }
        }).sort(orderByDate)

        const returned = completeAndCleanData(_report.device, values)

        this.state = {
            values: returned[0],
            estimated: returned[1],
            title: _report.device.boxId,
            startDate: _report.startDate,
            endDate: _report.endDate,
            startTime: _report.startTime,
            endTime: _report.endTime,
        }

        this.afterPrintHandler = this.afterPrintHandler.bind(this)
    }

    componentDidMount() {

        setTimeout(() => window.print(), 2000)

        window.addEventListener('afterprint', (event) => {
            setTimeout(() => this.afterPrintHandler(event), 4000)     
        })
    }

    componentWillUnmount() {
        window.removeEventListener('afterprint', this.afterPrintHandler)
    }

    render() {

        const _values = this.state.values,
            labels = _values.map(x => x.date.toLocaleTimeString()),
            values = _values.map(x => x.value)

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Temperatura',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: values
                },
                {
                    label: 'Estimada',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(241,205,99,0.4)',
                    borderColor: 'rgba(241,205,99,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(241,205,99,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(241,205,99,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.state.estimated.map(x => x.value)
                }
            ]
        }

        return (
            <div className="container device-report-wrapper">

                <div>
                    <h4>
                        CRITERIOS
                    </h4>
                    <p>
                        Contenedor: {this.state.title}
                    </p>
                    <ul>
                        <li>Fecha de inicio: &nbsp; {this.state.startDate}</li>
                        <li>Hora de inicio: &nbsp; {this.state.startTime}</li>
                        <li>Fecha de fin: &nbsp;{this.state.endDate}</li>
                        <li>Hora de fin: &nbsp;{this.state.endTime}</li>
                    </ul>
                </div>

                <div>
                    <h4>
                        Representación gráfica
                    </h4>
                    <Line data={data} />
                </div>

                <div className="device-report">
                    <h4>
                        Registro de temperaturas
                    </h4>
                    <table className="table table-striped table-bordered small">
                        <thead>
                            <tr>
                                <th>Hora y fecha</th>
                                <th>Temperatura (ºC)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _values.filter(x => !x.estimated).map(x => {
                                    return (
                                        <tr key={x.time}>
                                            <td>{x.date.toLocaleString()}</td>
                                            <td>{x.value}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ReportPage