import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Nav,
  NavItem
} from 'reactstrap'

import {
  FaTruck,
  FaCubes,
  FaWarehouse
} from 'react-icons/fa'

import bn from 'utils/bemnames'
import AppBrand from '../../Controls/AppBrand/index'
import './index.css'

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}, bem = bn.create('sidebar')

class Sidebar extends Component {

  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    modules: [],
    clientId: '-'
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`]
      return {
        [`isOpen${name}`]: !isOpen,
      }
    })
  }

  getModules = () => {
    const controls = [
      { to: '/trucks', text: ' Vehículos', ico: <FaTruck />, name: 'TRUCKS' },
      { to: '/containers', text: ' Contenedores', ico: <FaCubes />, name: 'CONTAINERS' },
      { to: '/', text: ' Almacenes', ico: <FaWarehouse />, name: 'APS' }
    ],
      clientId = sessionStorage.getItem('client'),
      clients = JSON.parse(sessionStorage.getItem('clients')),
      client = clients.find(x => x.clientId === clientId)
    
    let modules = []

    if (client !== undefined) {
      modules = controls.filter(
        x => client.modules.find(y => y === x.name) !== undefined
      )
    }
    return modules
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {

    const modules = this.getModules()
    
    return (
      <>
      <aside className={bem.b()}>

        <div className={bem.e('background')} style={sidebarBackground} />

        <div className={bem.e('content')}>
          <AppBrand />
          <Nav vertical>
            {modules.map((item) => (
              <NavItem key={item.to} className={bem.e('nav-item')}>
                <Link
                  className="text-uppercase nav-link"
                  to={item.to}
                  activeclassname="active"
                >
                  <span>
                    {item.ico}  {item.text}
                  </span>
                </Link>
              </NavItem>)
            )}
          </Nav>
        </div>
        {/* ONLY MOBILE */}
        {/* <div className="sidebar-client-selector-mobile-tablet">
            <NavItem>
              <ClientSelector />
            </NavItem>
        </div> */}
        <div className="sidebar-mobile-tablet">
            <Nav navbar className="mr-2 mobile-and-tablet">
                <Link
                    className="text-uppercase"
                    to='/'
                    activeclassname="active"
                >
                    <i className="fas fa-home"></i>
                </Link>
                {modules.map((item) => (
              <NavItem key={item.to} className={bem.e('nav-item')}>
                <Link
                  className="text-uppercase nav-link"
                  to={item.to}
                  activeclassname="active"
                >{item.ico}
                </Link>
              </NavItem>)
            )}
            </Nav>
        </div>
      </aside>
      
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const current = state.SideBarReducer
  return {
    clientId: current.clientId
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
