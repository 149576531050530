import { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import './index.css'

class GatewayPage extends Component {

    componentDidMount() {
        const { dispatch } = this.props

        dispatch(push('/'))

    }

    componentWillUnmount() { }

    render() {
        return 'redirect...'
    }
}

const mapStateToProps = (state, context) => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayPage)