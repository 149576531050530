import { SPINNER_ACTIONS } from './constant'

export const getShowSpinner = () => {
    return {
        type: SPINNER_ACTIONS.SHOW_SPINNER
    }
}

export const getHideSpinner = () => {
    return {
        type: SPINNER_ACTIONS.HIDE_SPINNER
    }
}