export const DEVICE_ACTIONS = {
    SET_IDENTITY: 'DEVICE_SET_IDENTITY',
    SET_LOADING: 'DEVICE_SET_LOADING',
    SET_LOADED: 'DEVICE_SET_LOADED',
    SET_START_DATE: 'DEVICE_SET_START_DATE',
    SET_END_DATE: 'DEVICE_SET_END_DATE',
    SET_START_TIME: 'DEVICE_SET_START_TIME',
    SET_END_TIME: 'DEVICE_SET_END_TIME'
}

export const DEFAULT_DEVICE = {
    logicalId: '',
    boxId: '',
    window: 60,
    setup: {
        step: 120,
        refresh: 900,
        time: -1
    },
    values:[]
}