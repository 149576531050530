import React, { Component } from 'react'
import TmsicRpiCardView from './view'
import './index.css'
import { orderByDate, getStart } from './helpers'


const uri = process.env.REACT_APP_DATA_API_URL,
    abortController = () => typeof AbortController !== "undefined" ? new AbortController() : null

class TmsicRpiCard extends Component {

    getItems = (device) => {
        const items = device.values === undefined ? [] : device.values
            .filter(x => !isNaN(x.payload.Temperature) && x.payload.Temperature > -100 && x.payload.Temperature < 127)
            .map(x => {
                let date = new Date(x.date.substring(0, 17) + '00.000Z')
                date.setHours(date.getHours() + this.props.setup.time)
                return {
                    date: date,
                    value: x.payload.Temperature
                }
            }).sort(orderByDate)
        return items
    }

    storeDataResponse(response) {
        const lastUpdate = new Date(),
                    items = this.getItems( response.devices.length === 1 ? response.devices[0] : {} )
        this.setState({
            lastUpdate: lastUpdate,
            items: items,
            itemsLoading: false,
        })
    }

    getData() {
        const url = `${uri}?ids=${this.props.id}&start=${getStart(this.props.window)}`
        if(this.state.controller !== null) {
            fetch(url, { signal: this.state.controller.signal })
            .then(httpResponse => httpResponse.json())
            .then( (response) => this.storeDataResponse(response) )
            .catch((error) => {
                console.log(error);
            })
        } else {
            fetch(url)
            .then(httpResponse => httpResponse.json())
            .then( (response) => this.storeDataResponse(response) )
            .catch((error) => {
                console.log(error);
            })
        }
    }

    

    constructor() {

        super()

        const start = new Date()

        this.state = {
            status: '-',
            items: [],
            itemsLoading: true,
            start: start,
            lastUpdate: start,
            controller: abortController(),
        }
    }

    componentDidMount() {
        this.getData()

        this.autoRefresh = setInterval(() => {
            this.getData()
        }, this.props.setup.refresh * 1000)

    }

    componentWillUnmount() {
        if (this.autoRefresh !== undefined && this.autoRefresh !== null) {
            clearInterval(this.autoRefresh)
        }
        if(this.state.controller !== null && this.state.controller !== undefined){
            this.state.controller.abort()
        }
    }

    render() {
            return (
                <TmsicRpiCardView
                    items={this.state.items}
                    itemsLoading={this.state.itemsLoading}
                    title={this.props.title}
                    id={this.props.id}
                    date={this.state.lastUpdate}
                    darktheme={this.props.darktheme}
                />
            )
    }
}

TmsicRpiCard.propTypes = {

}

TmsicRpiCard.defaultProps = {
    window: 60,
}

export default TmsicRpiCard