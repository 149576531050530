export const cleanTime = (date) => {
    const first = date.indexOf('.'),
        _last_plus = date.indexOf('+'),
        _last_minus = date.indexOf('-'),
        second = _last_plus > -1 ? _last_plus : _last_minus
    return `${date.substring(0, first)}${date.substring(second, date.length)}`
}

export const getTimeFromDateTime = (date) => {
    let datetime = new Date(date),
        hours = datetime.getHours(),
        minutes = datetime.getMinutes();

    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
}

/**
 * Obtiene el tiempo del objeto fecha pasado como argumento
 * con el formato indicado: hh:mm
 * @param {Date} date Referencia a la fecha de ejecución
 */
export const getShortTimeWithoutSeconds = (date) => {
    let hours = date.getHours(),
        minutes = date.getMinutes()

    if (hours < 10) {
        hours = `0${hours}`
    }

    if (minutes < 10) {
        minutes = `0${minutes}`
    }

    return `${hours}:${minutes}`;
}

/**
 * Obtiene el tiempo del objeto fecha pasado como argumento
 * con el formato indicado: hh:mm:ss
 * @param {Date} date Referencia a la fecha de ejecución
 */
export const getShortTimeFromDateTime = (date) => {
    let hours = date.getHours(),
        minutes = date.getMinutes(),
        seconds = date.getSeconds()

    if (hours < 10) {
        hours = `0${hours}`
    }

    if (minutes < 10) {
        minutes = `0${minutes}`
    }

    if(seconds < 10){
        seconds = `0${seconds}`
    }

    return `${hours}:${minutes}:${seconds}`;
}

export const getStringDateTime = (date = null) => {

    if (date === null || date === undefined) {
        date = new Date();
    }

    let month = (date.getMonth() + 1),
        day = date.getDate();

    if (month < 10) {
        month = `0${month}`;
    }

    if (day < 10) {
        day = `0${day}`;
    }

    return `${date.getFullYear()}-${month}-${day}`;
}

export const getCurrentTime = (minToAdd) => {
    let current = new Date()
    
    current.setMinutes(current.getMinutes() + minToAdd)

    let hours = current.getHours(),
        minutes = current.getMinutes();

    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    return `${hours}:${minutes}`;
}
